import React, { Component } from 'react';
import {ReactComponent as Res} from "../assets/no-search-results.svg";
import {ReactComponent as Search} from "../assets/search-member.svg";
import Grid from '@material-ui/core/Grid';
import nostyle from "../styles/noResult.module.scss";
import Context from '../context/Context';
import LandingPage from './landingPage';

class NoResult extends Component {
    constructor(props) {
        super(props);
        this.state = { noRes: false}
    }
    renderContent() {
        if (this.context.searchResult!=null) {
          return <div>
              <Res className = {nostyle.icon}/>
              <div className= {nostyle.noResult}>No search results</div>
          </div>;
        }
        else{
            return <div>
              <LandingPage />
          </div>;
        }
      }

    render() { 
        return (
            <Context.Consumer> 
            {context => ( <div>
            <Grid style= {{marginTop: "24vh", justifyContent: "center", display: "inline-grid"}} container>
                <Grid style = {{textAlign: "center"}}>
                    {this.renderContent()}
                </Grid>
            </Grid>
        </div> )}
        </Context.Consumer>);
    }
}
NoResult.contextType = Context;
export default NoResult;