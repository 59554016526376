export default class Item {
    constructor(data) {
        this.itemNbr = data['itemNbr'] ?? null;
        const itemDesc = data['itemDesc'] ?? "";
        this.itemDesc = itemDesc.trim();
        const itemDesc2 = data['itemDesc2'] ?? "";
        this.itemDesc2 = itemDesc2.trim();
        this.upcNbr = data['upcNbr'] ?? null;
        this.pluNbr = data['pluNbr'] ?? null;
        this.category = data['category'] ?? null;
        this.subCategory = data['subCategory'] ?? null;
        this.status = data['status'] ?? "";
        this.vendorNbr = data['nineDigitVendorNbr'] ?? null;
        const vendorName = data['vendorName'] ?? "N/A";
        this.vendorName = vendorName.trim();
        this.whpkQty = data['whpkQty'] ?? null;
        this.vnpkQty = data['vnpkQty'] ?? null;
        this.totOnHandQty = data['totOnHandQty'] ?? null;
        this.ossOnHandCaseQty = data['ossOnHandCaseQty'] ?? null;
        this.turnOnHandCaseQty = data['turnOnHandCaseQty'] ?? null;
    }

    get itemDescFull() {
      return `${this.itemDesc} - ${this.itemDesc2}`;
    }

    get itemImgUrl() {
      if (this.upcNbr) {
          return `https://scene7.samsclub.com/is/image/samsclub/` + "0".repeat(13-this.upcNbr.toString().length) + this.upcNbr;
      } else {
          return null;
      }
    }

    static getDummyObj() {
        return new Item(
          {
            "itemNbr": 304416,
            "itemDesc": "BNLS PORK LOIN WHOLE     ",
            "itemDesc2": "ORDER # 1/8\"TRIM         ",
            "upcNbr": 40643044169,
            "pluNbr": 0,
            "category": 76,
            "subCategory": 35,
            "status": "A",
            "vendorNbr": 690222,
            "nineDigitVendorNbr": "690222640",
            "vendorName": "SAMS FRESH REPLENISHMENT      ",
            "totOnHandQty": 367,
            "ossOnHandCaseQty": 0,
            "turnOnHandCaseQty": 367
        });
    }
}