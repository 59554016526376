import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
   breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1344,
        xl: 1984,
      },
    },
  palette: {
     primary: {
        main: "#0071e9" 
               },
     secondary: {
        main: "#ffcc80" 
                }
           }
});

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>,document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
