
import React, { Component } from 'react';
import UserContext from '../context/UserContext';
import { setAuthToken } from '../services/apiService';
import Cookies from 'js-cookie';
class Login extends Component {
  state = {success: true};
  componentDidMount() {
    try {
      console.log('Running in Env', window._env_.ENV);
      let user = undefined;
      let token = undefined;
      if(window._env_.ENV === 'Local') {
        user = {displayName: 'TestUser', userPrincipalName: "k0p05h9@walmart.com"};
        token = "dummy-token";
      } else {
        console.log('document.cookie', document.cookie);
        const encodedUserStr = Cookies.get('ddir-user');
        console.log('encoded user', encodedUserStr);
        const userStr = atob(encodedUserStr);
        user = JSON.parse(userStr)
        console.log('user', user);
        token = Cookies.get('ddir-access-token');
        console.log('access token', token);
      }
      setAuthToken(token, ()=> {
        alert("Session timeout");
        this.props.history.push('/');
      });
      this.context.handleLogin(user, ()=>{  
        this.props.history.push('/dashboard');
      });
    } catch(err) {
      console.error(err);
      this.setState({success: false});
    }
  }
  render() {
    if(this.state.success) {
      return (<div> 
        Logging in ...
      </div>);
    } else {
      return (<div> 
        Login failed! Do you want to try again?
        <button onClick={()=>this.props.history.push('/')}>login again</button>
      </div>);
    }
    
  }
}
Login.contextType = UserContext;
export default Login;