import * as Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver'
import { getDownloadData } from './apiService';
var _ = require('lodash');

async function downloadInventoryData(itemsList, selectedDCs) {
    const timestampStr = (new Date()).toLocaleString();
    const inventoryTableData = await getDownloadData(itemsList, selectedDCs);
    const workbook = await configWorkbookForInventoryTableData(itemsList, inventoryTableData);
    await writeFileAndDownload(workbook, `DDIR-${timestampStr}.xlsx`);
}

async function configWorkbookForInventoryTableData(itemsList, inventoryTableData) {

    let workbook = new Excel.Workbook();
    const ws = workbook.addWorksheet("Sheet1", { views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }] });
    ws.addRow(['', 'DC', 'ITEM', 'DESC', 'COMMENTS']);

    for (let [dc, dcItemData] of inventoryTableData) {


        for (let [item, itemData] of dcItemData) {
            let selectedItem = JSON.parse(item)

            for (let weeklyData of itemData) {

                let legacyCmtText = weeklyData['commentObj']['legacyCmtText']
                let commentTxt = weeklyData['commentObj']['commentTxt']

                ws.addRow(['DC-NBR', '', '', '', '', dc, 'ITEM', selectedItem['itemNbr'], 'DESC', selectedItem['itemDesc']]);
                ws.addRow(['YR/WK', '', '', '', '', 'SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'TOTAL']);
                ws.addRow([String(weeklyData['year']) + '/' + String(weeklyData['week']), '', '', '', '', ...weeklyData['date']])
                weeklyData['data']['demand'] ? ws.addRow(['DEMAND', dc, selectedItem['itemNbr'], selectedItem['itemDesc'], legacyCmtText, ...weeklyData['data']['demand'], getSum(weeklyData['data']['demand'])]) : ws.addRow([]);
                weeklyData['data']['ordered'] ? ws.addRow(['ORDERED', '', '', '', commentTxt, ...weeklyData['data']['ordered'], getSum(weeklyData['data']['ordered'])]) : ws.addRow([]);
                weeklyData['data']['received'] ? ws.addRow(['RECEIVED', '', '', '', '', ...weeklyData['data']['received'], getSum(weeklyData['data']['received'])]) : ws.addRow([]);
                weeklyData['data']['inventory'] ? ws.addRow(['INVENTORY', '', '', '', '', ...weeklyData['data']['inventory'], getAvg(weeklyData['data']['inventory'])]) : ws.addRow([]);
                weeklyData['data']['sales'] ? ws.addRow(['SALES', '', '', '', '', ...weeklyData['data']['sales'], getSum(weeklyData['data']['sales'])]) : ws.addRow([]);
                ws.addRow(['']);
                ws.addRow(['']);

                ws.lastRow.border = {
                    top: { style: 'double', color: { argb: 'FF000000' } }
                };

            }
        }
    }




    return workbook;
}

function getSum(arr) {
    let sum = _.sum(arr);
    return sum;
}

function getAvg(arr) {
    let avg = Math.round(_.mean(_.without(arr, null)));
    return avg;
}

async function writeFileAndDownload(workbook, filename) {
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), filename);
}

export { downloadInventoryData }