import moment from 'moment';

function getCurrentFirstDate() {
  const today = moment();
  const startDateWMWK = getStartDateOfWMWk(today);
  const firstDate = startDateWMWK.subtract(7, 'days');
  return firstDate;
}

function getFirstDateBack4Weeks(firstDate, minDate) {
  let prevFistDate = moment(firstDate).subtract(4*7, 'days');
  if(prevFistDate.isBefore(minDate)) prevFistDate = minDate;
  return prevFistDate;
}

function getFirstDateNext4Weeks(firstDate, maxDate) {
  let nextFirstDate = moment(firstDate).add(4*7, 'days');
  if(getEndDateFromFirstDate(nextFirstDate).isAfter(maxDate)) {
    nextFirstDate = getFirstDateFromEndDate(maxDate);
  }
  return nextFirstDate;
}

function getFirstDateFromPickedDate(inputDate, minDate, maxDate) {
  let firstDate = getStartDateOfWMWk(inputDate);
  firstDate = firstDate.subtract(7, 'days');
  if(firstDate.isBefore(minDate)) firstDate = minDate;
  else if(getEndDateFromFirstDate(firstDate).isAfter(maxDate)){
    firstDate = getFirstDateFromEndDate(maxDate);
  }
  return firstDate;
}

function getStartDateAndEndDateStr(firstDate) {
  const startDate = firstDate.format('YYYY-MM-DD');
  const endDate = getEndDateFromFirstDate(firstDate).format('YYYY-MM-DD');
  return {startDate, endDate};
}

function getStartDateAndEndDateStrForDownload() {

  const startDate = getStartDateOfWMWk(moment().subtract(7, 'days')).format('YYYY-MM-DD');
  const endDate = moment(startDate).add(7*4-1, 'days').format('YYYY-MM-DD');
  return {startDate, endDate};
}

function disableBack4Week(firstDate, minDate) {
  return firstDate.isSameOrBefore(minDate, 'day');
}

function disableNext4Week(firstDate, maxDate) {
  const endDate = moment(firstDate).add(7*4-1, 'days');
  return endDate.isSameOrAfter(maxDate, 'day');
}

// Helper
function getStartDateOfWMWk(inputDate) {
  // return the first Sat before/including inputDate
  let date = moment(inputDate); // make copy
  if(date.weekday() === 6) return date; // if input date is Sat
  else return date.weekday(-1); // find previous Sat
}

function getEndDateFromFirstDate(firstDate) {
  const endDate = moment(firstDate).add(7*4-1, 'days');
  return endDate
}

function getFirstDateFromEndDate(endDate) {
  const firstDate = moment(endDate).subtract(7*4-1, 'days');
  return firstDate
}

export {
  getCurrentFirstDate, 
  getFirstDateBack4Weeks, 
  getFirstDateNext4Weeks, 
  getFirstDateFromPickedDate, 
  getStartDateAndEndDateStr, 
  getStartDateAndEndDateStrForDownload,
  disableBack4Week, 
  disableNext4Week,
}