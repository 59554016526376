import Axios from 'axios';
import Item from '../models/Item';
import MetaData from '../models/MetaData';
import WeekTable from '../models/WeekTable';
import TrendTable from '../models/TrendTable';
import SearchResult from '../models/SearchResult';
import {getStartDateAndEndDateStr, getStartDateAndEndDateStrForDownload} from './calendarService';
import DDIRComment from '../models/DDIRComment';
import SavedSearch from '../models/SavedSearch';

const BaseUrl = window._env_.API_URL;
const UNAUTHORIZED = 401;
var headers = {}

function setAuthToken(token, unauthorizedCallBack) {
  if(window._env_.ENV === 'Local') {
    headers = {
      userId: 'testuser',
      rlId: 'testuser@samsclub.com',
      domain: 'home-office'
    };
  } else {
    headers = {'Authorization': `Bearer ${token}`};
    Axios.interceptors.response.use(
      response => response,
      error => {
        const {status} = error.response;
        if (status === UNAUTHORIZED && unauthorizedCallBack) {
          unauthorizedCallBack();
        }
        return Promise.reject(error);
     }
    );
  }
}

async function getMetaData() {
  const url = BaseUrl + '/metaData';
  const data = (await Axios.get(url, { headers })).data;
  const metaData = new MetaData(data);
  return metaData;
}

async function searchItems(itemNbrs,dcNbr) {
  const url = BaseUrl + '/searchItems';
  const searchResults = (await Axios.post(url, {
    'itemNbrs': itemNbrs,
    'legacySearch': false,
    'dcNbr': dcNbr
  }, { headers })).data.map(itemData => new SearchResult(itemData));
  return searchResults ?? [];
}

async function searchItemsForVendors(vendorNbrs, dcNbr) {
  const url = BaseUrl + '/searchItemsForVendors';
  const searchResults = (await Axios.post(url, {
    'vendorNbrs': vendorNbrs,
    'dcNbr': dcNbr
  }, { headers })).data;
  searchResults.itemInfo = searchResults.itemInfo.map(itemData => new SearchResult(itemData));
  // searchResults.validVendorNbrs = searchResults.validVendorNbrs.map(vendorNbr => vendorNbr.toString());
  return searchResults;
}

async function getItemDetails(itemNbr, legacyItemNbr, dcNbr) {
  const url = BaseUrl + '/fetchItem';
  const res = await Axios.post(url, {
    'itemNbr': itemNbr,
    'legacyItemNbr': legacyItemNbr,
    'dcNbr': dcNbr
  }, { headers });
  const item = new Item(res.data);
  return item;
}

async function ddirSearch(itemNbr, legacyItemNbr, dcNbr, firstDate) {
  const url = BaseUrl + '/searchDdir';
  const {startDate, endDate} = getStartDateAndEndDateStr(firstDate);
  const res = await Axios.post(url, {
    'itemNbr': itemNbr,
    'legacyItemNbr': legacyItemNbr,
    'dcNbr': dcNbr,
    'startDate': startDate,
    'endDate': endDate,
  }, { headers });
  const  weekTable = new WeekTable(res.data, startDate);
  return weekTable;
}

async function getDownloadData(items, dcNbrList) {
  const {startDate, endDate} = getStartDateAndEndDateStrForDownload();
  const url = BaseUrl + '/fetchDataToDownload';

  const res = await Axios.post(url,  {
    'itemNbrs': items,
    'dcNbrs': dcNbrList,
    'legacySearch': false,
    'startDate': startDate,
    'endDate': endDate,
  }, { headers });
  let resultData = res.data;
  var returnData = new Map();

  for ( let dc in resultData) {
    let dcData = resultData[dc];
    var formattedDcData = new Map();
    for (let itemNbr in dcData ) {
      formattedDcData.set(itemNbr, new WeekTable(dcData[itemNbr], startDate))
    }
    returnData.set(dc, formattedDcData);
  }

  return returnData;
  /*
  let resultData = new Map();
  for (let dc of dcNbrList) {
    let dcData = await ddirSearch(itemNbr, legacyItemNbr,  dc, firstDate);
    resultData.set(dc, dcData);
  }
  return resultData;
  */
}

async function searchDdirTrend(itemNbr, legacyItemNbr, dcNbr) {
  const url = BaseUrl + '/searchDdirTrend';
  const res = await Axios.post(url, {
    'itemNbr': itemNbr,
    'legacyItemNbr': legacyItemNbr,
    'dcNbr': dcNbr
  }, { headers });
  const  trendTable = new TrendTable(res.data);
  return trendTable;
}


async function saveComment(itemNbr, legacyItemNbr, dcNbr, commentObj) {
  const url = BaseUrl + '/saveComment';
  const res = await Axios.post(url, {
    'itemNbr': itemNbr,
    'legacyItemNbr': legacyItemNbr,
    'dcNbr': dcNbr,
    'wmtYrWkNbr': commentObj.wmtYrWkNbr,
    'id': commentObj.id,
    'comment': commentObj.commentTxt
  }, { headers });
  const updatedCommentObj = new DDIRComment(res.data);
  return updatedCommentObj;
}


async function getSavedSearch() {
    const url = BaseUrl+`/getSavedSearch`;
    const savedSearchList = (await Axios.get(url, {headers})).data.map(savedSearchData => new SavedSearch(savedSearchData));
    return savedSearchList;
}

async function addSavedSearch(savedSearchPayload) {
    const url = BaseUrl+'/saveSearch';
    const res = await Axios.post(url, savedSearchPayload, {headers})
    return res;
}

async function deleteSavedSearch( searchName) {
    const url = BaseUrl+`/deleteSavedSearch/${searchName}`;
    const res = await Axios.delete(url, {headers})
    return res;
}


export { setAuthToken, getMetaData, getDownloadData, searchItems, getSavedSearch, addSavedSearch, deleteSavedSearch, getItemDetails, ddirSearch, searchDdirTrend, saveComment, searchItemsForVendors };