import React, { Component } from 'react';
import moment from 'moment';
import styles from "./../styles/HeaderFooterStyles.module.scss";

export class Footer extends Component {
  render() {
    return(
      <footer color="inherit" className={styles.footer}>
        <div className={styles.footerText}>© {moment().year()} Sam’s Club. All rights reserved.</div>
      </footer>
    );
  }
}
export default Footer;