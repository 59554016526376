import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CommentSection from './comments';

import tabStyles from "../styles/TableComponent.module.scss";
import '../styles/table.css';

const _ = require('lodash');
const useStyles = makeStyles({
    table: {
      minWidth: 650,
      "& .MuiTableCell-root": {
        borderBottom: "0px solid rgba(224, 224, 224, 1)",
        boxShadow: "inset -1px 0px 0px #CAE0F9, inset 0px -1px 0px #CAE0F9",
        fontFamily: ["canada-type-gibson", "Roboto", "sans-serif"],
      },
      "& .MuiTableCell-head": {
        backgroundColor: "#F1F5FB",
        color: "#00172F",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
        fontSize: "14px"
      }
    }
});

const DataTableComponent = (props) => {
    const classes = useStyles();
    // Will remove after cheking the Compare weeks' UX
    const formatDateHeader = (dateStr) => {
        const parsedDate = moment(dateStr);
        if (parsedDate.isValid()) {
            if (props.origin === "weeks") {
                return parsedDate.format('ddd');
            } else {
                return parsedDate.format('MM/DD');
            }
        }
        return dateStr;
    };
    const formatWeekDays = (dates) => {
        const initDate = moment(dates[0]).format('MM/DD');
        const lastDate = moment(dates[dates.length -1]).format('MM/DD');
        return `${initDate} - ${lastDate}`;
    };
    const formatPeriod = (dates) => {
        const initDate = moment(dates[0]).format('MMMM DD');
        const lastDate = moment(dates[dates.length -1]).format('MMMM DD');
        return `${initDate} - ${lastDate}`;
    };
    const table = props.table;
    const getCssClass = (cell, cellVal, row, idx) => {
        let cellCssClass = tabStyles.dataColumn;
        if (props.origin === "weeks"){
            if (cell === "demand" && cellVal !== null && !!table[row].data["sales"] && cellVal > table[row].data["sales"][idx]){
                cellCssClass = tabStyles.dataColumnRed;
            } else if (cell === "received" && table[row].manualAdjqty !== undefined && table[row].manualAdjqty[idx] > 0){
                cellCssClass = tabStyles.dataColumnBlue;
            }
            if(cellVal === null){
                cellCssClass = cellCssClass + ' ' + tabStyles.textDim;
            }
            if (table[row] && table[row].date && moment(table[row].date[idx]).isSame(new Date(), 'day')) {
                cellCssClass = cellCssClass + ' ' + tabStyles.borderLeftRight;
                if (cell === 'date')
                    cellCssClass = cellCssClass + ' ' + tabStyles.borderTop;
                else if (cell === 'sales')
                    cellCssClass = cellCssClass + ' ' + tabStyles.borderBottom;
            }
        }
        return cellCssClass;
    };
    const dayNames = ["Sat","Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

    const getSumCellValue = (cell, currentRow) => {

        if (props.itemNum === 0) {
            return null;
        }

        if (cell === 'date') {
            return '';
        }

        if (cell === 'inventory' && props.origin === "weeks") {
            let nonNullInv = _.without(currentRow.data[cell], null);
            if (nonNullInv.length === 0)
                return 0;

            return  Math.round(_.mean(nonNullInv));
        }

        return _.sum(currentRow.data[cell]);

    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" className={classes.table} aria-label="simple table">
                        <TableHead>
                        {Object.keys(table).map((row, val) => (
                            val === 0 &&
                            <TableRow key={row} className={tabStyles.tabHeader}>
                                {props.origin === "weeks" && (table[row].week ?
                                    (<TableCell className={tabStyles.moveLeft}>Week / {table[row].year}</TableCell>)
                                    :(<TableCell className={tabStyles.moveLeft}>Week{table[row].year}</TableCell>))
                                }
                                {props.origin !== "weeks" && <TableCell className={tabStyles.moveLeft}>Period</TableCell>
                                }
                                <TableCell>Area</TableCell>
                                {props.origin === "weeks" && dayNames.map((cell, idx) => (
                                    <TableCell key={cell} align="right">{dayNames[idx]}</TableCell>))
                                }
                                {props.origin !== "weeks" && table[row].date.map(date => (
                                    <TableCell key={date} align="right">{formatDateHeader(date)}</TableCell>))
                                }
                                <TableCell align="right">Total</TableCell>
                            </TableRow>))
                        }
                        </TableHead>
                    <TableBody>
                    {Object.keys(table).map((row) => (
                        Object.keys(table[row].data).map((cell, value) => (
                        <TableRow key={value} className={value === Object.keys(table[row].data).length-1  ? tabStyles.rowData : ''}>
                            {value === 0 &&
                            <TableCell rowSpan={Object.keys(table[row].data).length} className={tabStyles.tabFirstColumn}>
                                {table[row].week &&
                                    <>
                                        <div>
                                            <span className={tabStyles.calendarWeek}>{props.origin === 'weeks' ? `Week ${table[row].week}`:formatPeriod(table[row].date)}</span>
                                            {props.origin === 'weeks' && <span className={tabStyles.calendarWeekDay}>{formatWeekDays(table[row].date)}</span>}
                                        </div>
                                        { props.origin === "weeks" &&
                                            <CommentSection commentObj={table[row].commentObj} />
                                        }
                                    </>
                                } 
                            </TableCell>}
                            <TableCell key={value} className={tabStyles.dataColumn}>{cell}</TableCell>
                            {table[row].data[cell].map((cellVal, idx) =>
                                <TableCell key={idx} align="right" className={getCssClass(cell, cellVal, row, idx)}>{cellVal === null ? '': cellVal}</TableCell>
                            )}
                            <TableCell className={tabStyles.totalColumn + + props.item !== 0 && cell === 'date' && tabStyles.textDim} align="right">{getSumCellValue(cell, table[row])}</TableCell>
                        </TableRow>
                        ))
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default DataTableComponent;
