import React, { Component } from 'react';
import InventoryTable from './inventory';
import CompareWeek from './compareWeek';
import Context from '../context/Context';


class SwitchView extends Component {
    render() {
        return (<div>
            {
                this.context.switchSearch === 'week' ?
                    <InventoryTable /> :
                    <CompareWeek />

            }
        </div>);
    }
}
SwitchView.contextType = Context;
export default SwitchView;