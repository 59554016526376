import React, { Component } from "react";
import itemStyle from "../../styles/ItemStyles.module.scss";
import Grid from "@material-ui/core/Grid";
import ItemDetails from "./itemDetails";
import Context from "../../context/Context";
import DurationSelector from "./durationSelector";
import { getItemDetails } from "../../services/apiService";
import CircularProgress from "@material-ui/core/CircularProgress";

class ItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: null,
      isLoading: false,
    };
  }
  currentSelectedItem = null;
  fetchItemDetails() {
    const { selectedItem, searchedDCNbr } = this.context;
    if( !this.state.isLoading && 
        selectedItem &&
        (selectedItem !== this.currentSelectedItem)
      ) {
          this.currentSelectedItem = selectedItem;
            this.setState({isLoading: true, itemDetails: null}, ()=> {
                getItemDetails(selectedItem.itemNbr, selectedItem.legacyItemNbr , searchedDCNbr)
                .then(itemDetails => {
                    if(Object.entries(itemDetails).length !== 0) {
                        this.setState({itemDetails: itemDetails});
                    }
                    else {
                        this.context.showMsgCallback('Failed to fetch Item Information!');
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.context.showMsgCallback('Failed to fetch Item Information!!');
                })
                .finally(() => {
                  this.setState({ isLoading: false});
                })
            });
    }
}
  
  render() {
    const { itemDetails, isLoading } = this.state;
    this.fetchItemDetails();
    if (isLoading) {
      return (
          <div className={itemStyle.loadingStyle} style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress size={40} />
          </div>
      );
    }

    const showDurationSelector = this.context.showDurationSelector;
    const searchedDCNbr = this.context.searchedDCNbr;


    return (
      <Context.Consumer>
        {(context) => (
          <div className={itemStyle.itemCard} >
            
            <div className={itemStyle.DcFont}>
              {itemDetails ? 
                <span>DC {searchedDCNbr}</span> : 
                <span>-- --</span>
              }
            </div>
            
            {/* Titile & 4 Weekly button */}
            <div className={itemStyle.titleWeekCard}>
              <div className={itemStyle.ItemFontTitle}>
                  {itemDetails
                  ? <span>Item {itemDetails["itemNbr"]}</span>
                  : <span>Item --</span>
                  }
                </div>
                <div  className={itemStyle.weekCard}>
                    { showDurationSelector && <DurationSelector />}
              </div>
            </div>

            <Grid container style={{ textAlign: "-webkit-center" }} spacing={1}>
              <Grid style={{ textAlign: "-webkit-center" }} item>
                <ItemDetails item={itemDetails} />
              </Grid>
            </Grid>
          </div>
        )}
      </Context.Consumer>
    );
  }
}
ItemComponent.contextType = Context;
export default (ItemComponent);