import React, { useState, useEffect } from "react";
import moment from 'moment';
import { getFirstDateFromPickedDate, getFirstDateBack4Weeks, getCurrentFirstDate, getFirstDateNext4Weeks, disableBack4Week, disableNext4Week } from "../services/calendarService";
import { useMyContext } from "../context/Context";
import styles from "./../styles/datePicker.module.scss";
import { ReactComponent as Arrow } from "../assets/arrow.svg";

const DatePicker = ({ handleClose }) => {
    const context = useMyContext();
    const [currentMonth, setCurrentMonth] = useState(context.firstDate);
    const [selectedDate, setSelectedDate] = useState(context.firstDate);
    const dayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    const onDateClick = date => {
        if (date && date <= context.calEndDate && date >= context.calStartDate) {
            setSelectedDate(date);
            const newDate = getFirstDateFromPickedDate(date, context.calStartDate, context.calEndDate);
            context.firstDateChangeCallback(newDate);
            handleClose();
        }
    };

    const handleMonthChange = (type) => {
        if (type === "next-month" && currentMonth <= context.calEndDate)
            setCurrentMonth(moment(currentMonth).add(1, "months"));
        else if (type === "previous-month" && currentMonth >= context.calStartDate)
            setCurrentMonth(moment(currentMonth).subtract(1, "months"));
    }

    const onClickBack = () => {
        const newDate = getFirstDateBack4Weeks(context.firstDate, context.calStartDate);
        context.firstDateChangeCallback(newDate)
    };
    const onClickCurrent = () => {
        const newDate = getCurrentFirstDate();
        context.firstDateChangeCallback(newDate)
    };
    const onClickForward = () => {
        const newDate = getFirstDateNext4Weeks(context.firstDate, context.calEndDate);
        context.firstDateChangeCallback(newDate)
    };

    useEffect(() => {
        setCurrentMonth(context.firstDate);
        setSelectedDate(context.firstDate);
    }, [context.firstDate])


    const renderDatePickerHeader = () => {
        const dateFormat = "MMMM YYYY";

        return (
        <>
            <div className={styles.calendarHeader}>
                <div className={styles.headerText} data-testid="month-year-val">{moment(currentMonth).format(dateFormat)}</div>
                <div className={styles.iconWrapper}>
                    <Arrow className={`${currentMonth >= context.calStartDate ? '' : styles.dim}`} onClick={() => handleMonthChange("previous-month")} data-testid="left-arrow-btn" />
                    <Arrow className={`${styles.arrowRight} ${currentMonth <= context.calEndDate ? '' : styles.dim}`} onClick={() => handleMonthChange("next-month")} data-testid="right-arrow-btn" />
                </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.dayNamesWrap}>
                {dayNames.map(dayName => <div key={dayName}>{dayName}</div>)}
            </div>
        </>
        );
      }

const renderDatePickerBody = () => {
    const monthStart = moment(currentMonth).startOf('month').toDate();
    const monthEnd = moment(monthStart).endOf('month').toDate();
    const startDate = moment(monthStart).startOf('week').toDate();
    const endDate = moment(monthEnd).endOf('week').toDate();

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = moment(day).format(dateFormat);
            const cloneDay = day;
            days.push(
                <div
                    className={`${styles.col} ${
                        !moment(day).isSame(monthStart, "month")
                            ? styles.disabled
                            : moment(day).isSame(selectedDate, "day") ? styles.selected : ""} ${moment(day).isSame(monthStart, "month") && (day <= context.calStartDate || day >= context.calEndDate) ? styles.dim : ''}`}
                    key={day}
                    onClick={() => onDateClick(moment(cloneDay).isSame(monthStart, "month") ? cloneDay : null)}
                >
                    <span className={`${styles.number} ${!moment(day).isSame(monthStart, "month") && (day <= context.calStartDate || day >= context.calEndDate) ? styles.disabledColor : ''}`}>{formattedDate}</span>
                </div>
            );
            day = moment(day).add(1, "days");
        }
        rows.push(
            <div className={styles.row} key={day}>
                {days}
            </div>
        );
        days = [];
    }
    return <div className={styles.body}>{rows}</div>;
}

return (
    <div className={styles.calendar}>
        {renderDatePickerHeader()}
        {renderDatePickerBody()}
        <div className={styles.bottomWrap}>
            <button onClick={onClickCurrent} data-testid="this-month-btn">Current 4 Weeks</button>
            <button onClick={onClickForward} disabled={disableNext4Week(context.firstDate, context.calEndDate)} data-testid="next-month-btn">Next 4 Weeks</button>
            <button onClick={onClickBack} disabled={disableBack4Week(context.firstDate, context.calStartDate)} data-testid="last-month-btn">Back 4 Weeks</button>
        </div>
    </div>
);
}

export default DatePicker;