import React, { Component } from 'react';
import  "../styles/invenTable.css"
import Context from '../context/Context';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTableComponent from './DataTableComponent';
import {ddirSearch} from  '../services/apiService';


class InventoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {table: [{date: ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                                commentObj : {week : 3720, comment : "test comment 1"},
                                week: 37, year : 2020,
                                data: {demand : [350,555,678,432,590,666,878],
                                ordered : [345,555,678,432,567,666,878],
                                received : [345,555,678,432,567,666,878],
                                inventory : [345,555,678,432,567,666,878],
                                sales : [345,555,678,432,567,666,878]},
                                manualAdjqty : [0,1,0,0,1,1,0]},
                                {date: ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                                commentObj : {week : 3820, comment : "test comment 2"},
                                week: 38, year : 2020,
                                data: {demand : [345,555,700,432,567,666,878],
                                ordered : [345,555,678,432,567,666,878],
                                received : [345,555,678,432,567,666,878],
                                inventory : [345,555,678,432,567,666,878],
                                sales : [345,555,678,432,567,666,878]},
                                manualAdjqty : [0,0,0,0,0,1,0]},
                                {date: ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                                commentObj : {week : 3920, comment : "test comment 3"} ,
                                week: 39, year : 2020,
                                data: {demand : [345,555,678,432,567,666,878],
                                ordered : [345,555,678,432,567,666,878]}},
                                {date: ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                                commentObj : {week : 4020, comment : ""},
                                week: 34, year : 2020,
                                data: {demand : [345,555,678,432,567,666,878],
                                ordered : [345,555,678,432,567,666,878]}},

                        ],
                        isLoading: false,
    }
    this.currentSelectedItem = null;
    this.firstDate = null;
    }

    getDailyInventory = Array.from({ length: 7 }).fill(null);
    areaData = ['demand', 'ordered', 'received', 'inventory', 'sales'];
    getAreaData = this.areaData.reduce((acc, value) => {
        return {...acc, [value]: this.getDailyInventory};
    }, {});
    getWeekData = {
        commentObj : {},
        week: null,
        year : null,
        data: this.getAreaData,
        manualAdjqty :  Array.from({ length: 7 }).fill(0)
    };
    emptyDdirData = {table:Array.from({ length: 4 }).fill(this.getWeekData)}

    getTable(){
        this.setState({ table:[], isLoading: true });
        this.currentSelectedItem = this.context.selectedItem;
        if (this.context.selectedItem.itemNbr === 0) {
            this.firstDate = this.context.firstDate;
            this.setState({table: this.emptyDdirData.table, isLoading: false});
        } else {
            ddirSearch(
                this.context.selectedItem.itemNbr,
                this.context.selectedItem.legacyItemNbr,
                this.context.searchedDCNbr,
                this.context.firstDate
              ).then(data => {
                console.log("DDIR Search Inventory", data);
                this.firstDate = this.context.firstDate;
                this.setState({table: data, isLoading: false});
              }).catch( err => {
                console.error(err);
                this.context.showMsgCallback('DDIRSearch Failed');
                this.setState({table: [], isLoading: false});
              });
        }
    }
    loadTableIfNeeded() {
        const { selectedItem, firstDate } = this.context;
        if( !this.state.isLoading && 
            selectedItem &&
            (this.currentSelectedItem !== selectedItem || this.firstDate !== firstDate)) 
            this.getTable();
    }

    render() { 
        this.loadTableIfNeeded();
        return ( 
        <Context.Consumer> 
            {context => (
                <div>
                 { !this.state.isLoading ? 
        <div>
           <DataTableComponent
                origin = {"weeks"}
                dc={context.searchedDCNbr}
                itemNum={context.selectedItem ? context.selectedItem.itemNbr : null}
                handleClickComment = {this.handleClickComment}
                table = {this.state.table}
            />
            {/* {this.state.table.map((table, idx) =>(
            <TableComp key = {idx} origin = {"weeks"} dc={context.searchedDCNbr} itemNum={context.selectedItem.itemNbr} handleClickComment = {this.handleClickComment} table = {table}/>
            ))} */}
        </div> :
        <CircularProgress size= {100} style = {{margin: "10% 45%"}}/>}
        </div>
        )}
        </Context.Consumer> );
    }
}
InventoryTable.contextType = Context;
export default InventoryTable;