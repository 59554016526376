export default class SavedSearch {
  constructor(data) {
    this.itemNbr = data['itemNbr'];
    this.vendorNbr = data['vendorNbr'];
    this.dcNbr = data['dcNbr'];
    this.userName = data['userName'];
    this.timeStamp = data['timeStamp'];
    this.searchName = data['searchName'];
  }
  getPayload() {
    return {
      "itemNbr": this.itemNbr,
      "vendorNbr": this.vendorNbr,
      "dcNbr": this.dcNbr,
      "userName": this.userName,
      "timeStamp": this.timeStamp,
      "searchName": this.searchName
    }
  }
  static getDummyObj() {
    return new SavedSearch({
      "itemNbr": [
        1234,
        2345,
        4567
      ],
      "vendorNbr": [987654321, 123456789],
      "dcNbr": 6042,
      "userName": "x0h01ou",
      "timeStamp": "2020-08-18 23:49:08.515927",
      "save": false,
      "searchName": "test collection"
    });
  }
}