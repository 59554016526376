import React, { Fragment, Component } from 'react';
import Login from './components/login';
import Home from './components/home';
import GuardedRoute from './components/GuardedRoute';
import UserContext from './context/UserContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const ssoURL = window._env_.SSO_URL;
const ssoLogout = window._env_.SSO_LOGOUT;
class App extends Component {
  constructor(props) {
    super(props);
    this.isAuthenticated = false;
    this.handleLogin = (user, completion) => {
      this.isAuthenticated = true;
      this.setState({ user: user }, completion());
      try {
        var dataLayer = window.dataLayer = window.dataLayer || [];
        const { displayName, userPrincipalName } = user;
        console.log('data layer')
        dataLayer.push({
          event: "user_login",
          displayName,
          userPrincipalName,
        });
      } catch (err) {
        console.info(`GA event not published: ${err}`);
      }
    }
    this.state = {
      user: { displayName: "TestUser"},
      error: null,
      handleLogin: this.handleLogin,
      logout: () => {
        window.location.href = ssoLogout;
      }
    };
  }

  render() {
    return (
      <Fragment >

        <Router>
          <UserContext.Provider value={this.state}>
            <Switch>
              <Route exact path='/' component={() => {
                this.isAuthenticated = false;
                window.location.href = ssoURL;
                return null;
              }} />
              <Route path='/login' component={Login} />
              <GuardedRoute path='/dashboard' component={Home} auth={this.isAuthenticated} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </UserContext.Provider>
        </Router>

      </Fragment>
    );
  }
}

export default (App);