import React, { useState, useRef,useEffect }  from 'react';
import { useMyContext } from '../../context/Context';
import {
  getCurrentFirstDate, 
  getFirstDateBack4Weeks, 
  getFirstDateNext4Weeks, 
  getFirstDateFromPickedDate,
  disableBack4Week, 
  disableNext4Week,
} from '../../services/calendarService';
import 'date-fns';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({   
    root: {
      '& > *': {
        margin: theme.spacing(0.5),
      }
    }
  }));

  const buttonStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        
    },
    outlined: {
        '&:hover': { 
            backgroundColor: '#5469d4'
        },
    }
  }));
const durations = {
    backFourWeeks: 'Back 4 Weeks',
    currentFourWeeks: 'Current 4 Weeks',
    nextFourWeeks: 'Next 4 Weeks',
    pickADate: 'Pick a Date'
}

export default function DurationSelector () {
    const context = useMyContext();
    const classes = useStyles();
    const buttonClass = buttonStyles();
    const [anchorEl, setAnchorEl] = useState();
    const inputEl = useRef(null);
    // Btn Callback
    const onClickBack = () => {
      const newDate = getFirstDateBack4Weeks(context.firstDate, context.calStartDate);
      context.firstDateChangeCallback(newDate)
    };
    const onClickCurrent = () => {
      const newDate = getCurrentFirstDate();
      context.firstDateChangeCallback(newDate)
    };
    const onClickForward = () => {
      const newDate = getFirstDateNext4Weeks(context.firstDate, context.calEndDate);
      context.firstDateChangeCallback(newDate)
    };
    const onClickPickDate = (date) => {
      const newDate = getFirstDateFromPickedDate(date, context.calStartDate, context.calEndDate);
      context.firstDateChangeCallback(newDate)
    };

    useEffect(() => {
        setAnchorEl(inputEl.current);
    }, []);

    return (
        <div className={classes.root} ref={inputEl}>
        <Button 
        className={buttonClass.root} 
        variant="outlined" size="medium" 
        onClick={onClickBack}
        disabled={disableBack4Week(context.firstDate, context.calStartDate)}>
            {durations.backFourWeeks}
        </Button>
        <Button 
        className={buttonClass.root} 
        variant="outlined" size="medium" 
        onClick={onClickForward} 
        disabled={disableNext4Week(context.firstDate, context.calEndDate)}>
            {durations.nextFourWeeks}
        </Button>
        <Button className={buttonClass.root} variant="outlined" size="medium" onClick={onClickCurrent} >
            {durations.currentFourWeeks}
        </Button>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                minDate={context.calStartDate}
                maxDate={context.calEndDate}
                autoOk
                variant="inline"
                openTo="date"
                value={context.firstDate}
                TextFieldComponent={(props) => (<Button onClick={props.onClick}
                    value={props.value}
                    onChange={props.onChange}
                    className={buttonClass.root} variant="outlined" size="medium">
                    {durations.pickADate}
                </Button>)}
                PopoverProps = {{anchorEl: anchorEl, anchorOrigin: {horizontal: 'right', vertical: 'bottom'}}}
                onChange={onClickPickDate}/>
    </MuiPickersUtilsProvider>
    </div>
        );
}
