import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import itemStyle from "../../styles/ItemStyles.module.scss";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    textAlign: "center",
  },
  tebleCell: {
    fontWeight: "bold",
  },
});

function ItemDetails(props) {
    const { item } = props;
    
    return (
      <div className={itemStyle.itemDetails}>
        <Paper  className={itemStyle.imgPaper}>
          <img
            height="102"
            width="102"
            src={item && item.itemImgUrl ? item.itemImgUrl : `--` }
            alt="No Item"
            border="0"
            float="center"
          />
        </Paper>

        <div className={itemStyle.itemDetailRightDard}>
          <ArrowRightIcon color="primary" className={itemStyle.rightArrow} />
          
          <div className={itemStyle.detailFont}>Details</div> <br/>

          <div className={itemStyle.detailTitle}>
            {item && item["itemDesc"] ? item["itemDescFull"] : `--` }
          </div>
          
          <div className={itemStyle.tableContainer} >
            <div className={itemStyle.tableitem}>
              <div className={itemStyle.tableitemTitle}>Category</div>
              <div className={itemStyle.tableitemDetail}>
                {item && item["category"] ? item["category"] : `--` }
              </div>
            </div>
            
            <div className={itemStyle.tableitem}>
              <div className={itemStyle.tableitemTitle}>Vendor Name</div>
              <div className={itemStyle.tableitemDetail} >
                {item && item["vendorName"] ? item["vendorName"] : `--` }
              </div>
            </div>
          
            <div className={itemStyle.tableitem}>
              <div className={itemStyle.tableitemTitle}>Vendor #</div>
              <div className={itemStyle.tableitemDetail}>
                {item && item["vendorNbr"] ? item["vendorNbr"] : `--` }
              </div>
            </div>
            
            <div className={itemStyle.tableitem}>
              <div className={itemStyle.tableitemTitle}>Out storage</div>
              <div className={itemStyle.tableitemDetail}>
                {item && item["ossOnHandCaseQty"] ? item["ossOnHandCaseQty"] : '--'  }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
}
export default withStyles(styles)(ItemDetails);
