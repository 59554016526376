export default class DDIRComment {
    constructor(data) {
      this.id = data['id'] ?? "";
      this.userId = data['userId'] ?? "";
      this.commentTxt = data['commentTxt'] ?? "";
      this.commentDate = data['commentDate'] ?? "";
      this.legacyCmtText = data['legacyCmtText'] ?? "";
      this.wmtYrWkNbr = data['wmtYrWkNbr'] ?? "";
    }
    static getDummyObj() {
        return new DDIRComment({
          "id": "",
          "userId": null,
          "commentTxt": "test comment",
          "commentDate": null,
          "legacyCmtText": "43/47 ",
          "wmtYrWkNbr" : ""
        });
    }
}