import React, { Component } from 'react';
import impstyles from '../styles/SearchStyles.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Autocompl from './autoComplete';
import '../styles/table.css';
import Context from '../context/Context';
import { searchItems, searchItemsForVendors } from '../services/apiService';
import Collapse from '@material-ui/core/Collapse';
import { PrimaryButton } from './common/customButton';
import { Typography } from '@mui/material';
import Button from '@material-ui/core/Button';
import {BlueButton, MinimalButton} from './common/customButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MultiSelect from './MultiSelect';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiSvgIcon-root': {
      height: 30,
      width: 30,
    },
  },
  paper: {
    height: 398,
    width: 297,
    maxWidth: 297,
    // margin: theme.spacing(4),
    textAlign: 'left',
  },
});

const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const RadioCheckedIcon = styled(RadioIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const DCAutoComplete = styled(Autocompl)({
  root: {
    "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
      flexWrap: "nowrap",
      overflowX: "hidden"  // or "hidden"
    }
  }
});

// Inspired by blueprintjs
function SearchRadioBtn(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemerrorMsg: '',
      vendorErrorMsg: '',
      dcerrorMsg: '',
      showItemSearch: true,
      itemNumbers: [],
      problematicItemNbrs: [],
      selectedDCNbr: '',
      dcList: props.dcList,
      vendorNbrs: [],
      problematicVendorNbrs: [],
      loading: false,
      collapse: false,
      setItemSearch: true,
      selectedDcList: [],
      searchType: 'itemNumbers',
    };
  }


  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount() {
    this.setState({
      itemNumbers: this.context.searchedItemNbrList,
      vendorNbrs: this.context.searchedVendorNbrList,
      showItemSearch: this.context.showItemSearch,
      selectedDcList: this.context.searchedDcNbrList,
    });
    if (this.context.showItemSearch === false) {
      this.setState({
        searchType: 'vendorIDs',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.dcList !== prevProps.dcList) || (this.state.dcList && (this.state.dcList[0] + '').length === 4)) {
      let list = [...this.props.dcList];
      for (let i in list) {
        if ((list[i] + '').length === 4) list[i] = '0' + list[i];
      }

      this.setState({
        dcList: list,
      });
    }
    if (
      this.props.selectedSavedSearch !== prevProps.selectedSavedSearch &&
      this.props.selectedSavedSearch
    ) {
      // Perform search on the selected Saved Search
      const savedSearch = this.props.selectedSavedSearch;
      console.log('Perform saved search: ', savedSearch);

      if (savedSearch.vendorNbr.length === 0) {
        this.setState(
          {
            itemNumbers: savedSearch.itemNbr,
            selectedDCNbr: savedSearch.dcNbr,
            showItemSearch: true,
          },
          () => {
            this.onSearch(new Event('Search by Saved Search'));
          }
        );
      } else {
        this.setState(
          {
            vendorNbrs: savedSearch.vendorNbr,
            selectedDCNbr: savedSearch.dcNbr,
            showItemSearch: false,
          },
          () => {
            this.onSearch(new Event('Search by Saved Search'));
          }
        );
      }
    }
  }
  searchForItems() {
    const { itemNumbers, selectedDcList } = this.state;
    if (itemNumbers.length === 0) {
        this.setState({ itemerrorMsg: 'Enter an item number' });
      return;
    }
    searchItems(itemNumbers, selectedDcList[0])
      .then((searchResults) => {
        this.cleanupAfterSearch();
        this.checkProblematicItemNbrs(searchResults);
        this.context.searchCallback(
          this.state.showItemSearch,
          itemNumbers,
          this.state.vendorNbrs,
          selectedDcList[0],
          searchResults,
          selectedDcList
        );
        this.context.selectSavedSearchCallback(null);
        this.context.showHideSidenav();
      })
      .catch((err) => {
        console.error(err);
        this.context.showMsgCallback('Something went wrong!');
        this.cleanupAfterSearch();
      });
  }

  searchForVendor() {
    const { vendorNbrs, selectedDcList } = this.state;
    // TODO `val`idate vendor numbers

    if (vendorNbrs.length === 0) {
        this.setState({ vendorErrorMsg: 'Enter a vendor number' });
      return;
    }

    searchItemsForVendors(vendorNbrs, selectedDcList[0])
      .then((searchResults) => {
        this.cleanupAfterSearch();
        let { itemInfo, validVendorNbrs } = searchResults;
        this.checkProblematicVendorNbrs(validVendorNbrs, vendorNbrs);
        this.context.searchCallback(
          this.state.showItemSearch,
          this.state.itemNumbers,
          vendorNbrs,
          selectedDcList[0],
          itemInfo,
          selectedDcList
        );
        this.context.selectSavedSearchCallback(null);
        this.context.showHideSidenav();
      })
      .catch((err) => {
        console.error(err);
        this.context.showMsgCallback('Something went wrong!');
        this.cleanupAfterSearch();
      });
  }

  regex = /^(([0-9]+)(,(?=[0-9]))?)+$/;
  onSearch = (e) => {
    e.preventDefault();
    if (this.state.selectedDcList.length === 0) { //select all DC if nothing is selected
      this.setState({
        selectedDcList: this.state.dcList,
        loading: true
      }, () => {
        this.searchItemVendor();
      });
    } else {
      this.setState({
        loading: true
      }, () => {
        this.searchItemVendor();
      });
    }
  };

  searchItemVendor() {
    if (this.state.showItemSearch) {
      this.searchForItems();
    } else {
      this.searchForVendor();
    }
  }

  cleanupAfterSearch() {
    this.setState(
      { loading: false, itemerrorMsg: '', vendorErrorMsg: '', dcerrorMsg: '' },
      () => {}
    );
  }

  resetSearch() {
    this.setState(
      { 
        itemerrorMsg: '',
        vendorErrorMsg: '',
        dcerrorMsg: '',
        showItemSearch: true,
        itemNumbers: [],
        problematicItemNbrs: [],
        selectedDCNbr: '',
        vendorNbrs: [],
        problematicVendorNbrs: [],
        loading: false,
        collapse: false,
        setItemSearch: true,
        selectedDcList: [],
        searchType: 'itemNumbers',
        searchedDcNbrList: []
       }
    )
  }

  checkProblematicItemNbrs(searchResults) {
    const { itemNumbers } = this.state;
    if (searchResults.length !== itemNumbers.length) {
      // generic error message
      const validItemNbrSet = new Set(searchResults.map((res) => res.itemNbr));
      const problematicItemNbrs = itemNumbers.filter(
        (itemNbr) => !validItemNbrSet.has(itemNbr)
      );
      console.log('problematicItemNbrs', problematicItemNbrs);
      this.setState({
        itemerrorMsg: 'Please check if all items are valid and authorized.',
        problematicItemNbrs,
      });
    }
  }
  checkProblematicVendorNbrs(validVendorNbrs, vendorNbrs) {
    if (validVendorNbrs.length !== vendorNbrs.length) {
      // converted to number since, backend outputs a number
      const problematicVendorNbrs = vendorNbrs.filter(
        (vendorNbr) => !validVendorNbrs.includes(Number(vendorNbr))
      );
      console.log('problematicVendorNbrs ', problematicVendorNbrs);
      this.setState({
        vendorErrorMsg:
          'Please check if all vendor numbers are valid and authorized.',
        problematicVendorNbrs,
      });
    }
  }
  autocompleteValCallback(val) {
    if (this.state.showItemSearch) {
      this.setState({ itemNumbers: val ?? [], itemerrorMsg: '' });
    } else {
      this.setState({ vendorNbrs: val ?? [], vendorErrorMsg: '' });
    }
  }
  selectDCCallback(val) {
    this.setState({ selectedDCNbr: val ?? [], dcerrorMsg: '' });
    // console.log("update selected DC", this.state);
  }

  handleSearchType(event) {
    this.setState({
      searchType: event.target.value,
    });
    if (event.target.value === 'vendorIDs') {
      this.setState({
        showItemSearch: false,
      });
    } else {
      this.setState({
        showItemSearch: true,
      });
    }
  }

  setSelectedDcList(val) {
    this.setState({
      selectedDcList: val,
    });
  }

  // handleLegacyChange = (event) => {
  //   this.setState({legacy : event.target.checked});
  // }

  render() {
    // console.log("Search Render", this.state);
    return (
      <Context.Consumer>
        {(context) => (
          <div role="tablist" className={impstyles.searchCard}>
            <div
              role="tab"
              tabIndex="-1"
              className={impstyles.searchTitle}
              onClick={() => this.toggleCollapse()}
              onKeyDown={() => this.toggleCollapse()}
              data-testid="toggleCollapse"
            >
              <span className={impstyles.title}> </span>
              <span style={{ flex: 1 }} />
            </div>
            {this.state.dcList.length > 0 && (
              <Collapse
                className={impstyles.collapseCard}
                in={!this.state.collapse}
                timeout="auto"
                unmountOnExit
              >
                <div className={impstyles.insideDiv} role="tablist">
                  <div className={impstyles.searchCategory}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="item-numbers-and-VendorID"
                        name="position"
                        value={this.state.searchType}
                        onChange={this.handleSearchType.bind(this)}
                        data-testid="radio-group"
                      >
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: 14,
                              fontFamily: 'canada-type-gibson',
                              marginTop: 0.5,
                            },
                          }}
                          value="itemNumbers"
                          className={impstyles.radioButtonSize}
                          control={<SearchRadioBtn />}
                          label="Item numbers"
                        />
                        <div className={impstyles.textSpacer} />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: 13,
                              fontFamily: 'canada-type-gibson',
                              marginTop: 0.5,
                            },
                          }}
                          value="vendorIDs"
                          className={impstyles.radioButtonSize}
                          control={<SearchRadioBtn />}
                          label="Vendor IDs"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div id="autocomp" style={{ marginTop: 9 }}>
                    <DCAutoComplete
                      style={{ border: 'none', marginTop: 9 }}
                      value={
                        this.state.showItemSearch
                          ? this.state.itemNumbers
                          : this.state.vendorNbrs
                      }
                      newAAutoVal={this.autocompleteValCallback.bind(this)}
                      errormsg={
                        this.state.showItemSearch
                          ? this.state.itemerrorMsg
                          : this.state.vendorErrorMsg
                      }
                    />
                  </div>
                </div>
                <div className={impstyles.dcSelector}>
                  <div style={{ padding: '5px 0px' }}>
                    <span className={impstyles.insideFont}>
                      {' '}
                      Select Distribution Centers{' '}
                    </span>
                  </div>
                  <div id='search-multiselect' className={impstyles.multiSelect}>
                    <MultiSelect
                      items={this.state.dcList}
                      selectAllLabel="Select all"
                      onChange={this.setSelectedDcList.bind(this)}
                      searchedDcValues={this.context.searchedDcNbrList}
                      triggerSearch={this.onSearch.bind(this)}
                    />
                  </div>
                  <div>
                    <span className={impstyles.dropdownSubtext}>
                      {' '}
                      If set to multiple or all you can toggle between them
                      quickly{' '}
                    </span>
                  </div>

                  <Typography
                    style={{
                      position: 'relative',
                      display: 'flex',
                      background: '#E7EDF6',
                      margin: '0',
                      left: '-16px',
                      width: '387px',
                      top: 'calc(100vh - 456px)',
                      height: '80px',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <MinimalButton
                      className={impstyles.closeButton}
                      variant="text"
                      onClick={this.props.showHideSidenav}
                    >
                      <div>Close</div>
                    </MinimalButton>
                    <BlueButton
                      className={impstyles.searchButton}
                      onClick={this.onSearch.bind(this)}
                      loading={this.state.loading}
                      disabled={
                        this.state.itemNumbers.length === 0 &&
                        this.state.vendorNbrs.length === 0
                      }
                      data-testid="PrimaryButtonClick"
                    >
                      {/* <SearchIcon style={{ marginRight: "5px" }} /> */}
                      Search
                    </BlueButton>
                  </Typography>
                </div>
              </Collapse>
            )}
            {this.state.dcList.length === 0 && <CircularProgress size= {50} style = {{margin: "80% 45%"}}/>}
          </div>
        )}
      </Context.Consumer>
    );
  }
}
Search.contextType = Context;
export default withStyles(styles)(Search);
