import React, { Component } from 'react';
import impstyles from "../../styles/SearchStyles.module.scss";
import Divider from '@material-ui/core/Divider';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { SecondaryButton } from '../common/customButton';
import { DownloadAlert } from '../common/alert';
import Context from  '../../context/Context';
import DownloadDialog from './DownloadDialog';

export default class BulkDownload extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isDownloading: false,
            showAlert: false,
            showDialog: false
        }
    }

    onClickDownload = () => {
        this.setState({showDialog: true})
    }
    handleDialogClose = () => {
        this.setState({showDialog: false})
    }
    handleDownloading = (_isDownloading) => {
        if (_isDownloading) {
            this.setState({
                isDownloading: true,
                showDialog: false, 
                showAlert: true
            });
        } else {
            this.setState({
                isDownloading: false,
                showAlert: false
            });
        }
        
    }
    render() {
        return (
            <Context.Consumer> 
                    {context => ( 
                    <div >
                        <div style = {{ padding : "15px"}}>
                            <span className={impstyles.title}>Bulk Downloads</span>
                        </div>
                        <Divider />
                        <div style = {{ padding : "15px"}}>
                                    {context.searchResult && context.searchResult.length>0 && 
                                    <div>
                                        <DownloadButton 
                                            loading={this.state.isDownloading} 
                                            style={{ marginBottom: "15px"}} 
                                            onClick={()=>this.onClickDownload()}>
                                                Selected Items
                                        </DownloadButton>   
                                    </div>}
                                    {this.context.dcNbrList.length>0 &&
                        <>
                        <DownloadDialog open={this.state.showDialog} handleClose={this.handleDialogClose} handleDownloading={this.handleDownloading}  />
                        <DownloadAlert open={this.state.showAlert} handleClose={() => {this.setState({showAlert: false})}}/>
                        </>
                        }         
                        
                        </div>
                    </div>)}
            </Context.Consumer>
         );
    }
}

BulkDownload.contextType = Context;

export const DownloadButton = props => {
    const { loading, onClick, style, children } = props;
    return <SecondaryButton 
    disabled={loading}
    loading={loading}
    onClick={onClick}
    style={style} 
    startIcon={<SaveAlt />}>
        {children}
    </SecondaryButton>
};