import moment from 'moment';
import DDIRComment from './DDIRComment';
export default class WeekTable {
    constructor(data, startDate) {
        const table = [];
        if(data.length>0) {
            data.forEach((eachTable, index)=>{

                const weekData = {
                    date : [],
                    commentObj : {},
                    week : {},
                    year : {},
                    data : {date:[], demand:[], ordered:[], received:[], inventory:[], sales:[]},
                    manualAdjqty : []}
                weekData['week'] = eachTable['walmartWeek'];
                weekData['year'] = eachTable['walmartYearWk'].toString().substring(0,4);

                if(eachTable['ddirComment']!=null){
                    weekData['commentObj'] = new DDIRComment(eachTable['ddirComment']);
                }
                else{
                    weekData['commentObj'] = { id:"", userId:"", commentTxt: "", commentDate: "", legacyCmtText : "", wmtYrWkNbr: eachTable['walmartYearWk']};
                }

                eachTable['ddirRows'].forEach(row => {
                    weekData['data']['date'].push(moment(row['whseShipDate']).format('MMM D'));
                    weekData['data']['demand'].push(row['demandQty']);
                    weekData['data']['ordered'].push(row['orderedQty']);
                    weekData['data']['received'].push(row['receivedQty']);
                    weekData['data']['inventory'].push(row['inventoryQty']);
                    weekData['data']['sales'].push(row['sales']);
                    weekData['manualAdjqty'].push(row['inventoryAdjustment']);
                    weekData['date'].push(row['whseShipDate']);
                })
                if (eachTable['ddirRows'].length === 0 && startDate) {//item not available
                    for (let i = 0; i < 7; i++) {
                        let date = moment(startDate).add(i + (index * 7), 'days');
                        weekData['data']['date'].push(date.format('MMM D'));
                        weekData['data']['demand'].push('');
                        weekData['data']['ordered'].push('');
                        weekData['data']['received'].push('');
                        weekData['data']['inventory'].push('');
                        weekData['data']['sales'].push('');
                        weekData['manualAdjqty'].push('');
                        weekData['date'].push(date.format('YYYY-MM-DD'));
                    }
                }
                if(moment(weekData['date'][0]).isAfter(moment())){
                    delete weekData['data']['received'];
                    delete weekData['data']['inventory'];
                    delete weekData['data']['sales'];
                }
                table.push(weekData);
            })
        }
        return table;
    }

    static getDummyObj() {
        return new WeekTable([
                {
                "date": ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                "commentObj": {
                    "week": 3720,
                    "comment": "test comment 1"
                },
                "week": 37,
                "year": 2020,
                "data": {
                    "date" : ['JAN 1','JAN 2', 'JAN 3', 'JAN 4', 'JAN 5', 'JAN 6', 'JAN 7'],
                    "demand" : [350,555,678,432,590,666,878],
                    "ordered" : [345,555,678,432,567,666,878],
                    "received" : [345,555,678,432,567,666,878],
                    "inventory" : [345,555,678,432,567,666,878],
                    "sales" : [345,555,678,432,567,666,878]
                },
                "manualAdjqty" : [0,1,0,0,1,1,0]
                }
            ])
    }
}