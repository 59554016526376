
import React, { Component } from 'react';
import Dashboard from './dashboard';
import Footer from '../layouts/footer';
import UserContext from '../context/UserContext';
class Home extends Component {
  state = {}
  render() {
    return (
      <UserContext.Consumer>
        {context => (
          <div role='main'>
            <Dashboard />
            <Footer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Home;