import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import impstyles from '../styles/Dashboard.module.scss';
import SideNav from './sidenav';
import RightGrid from './rightgrid';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Context from '../context/Context';
import UserContext from '../context/UserContext';
import {
  getMetaData,
  searchItemsForVendors,
  searchItems,
} from '../services/apiService';
import { getCurrentFirstDate } from '../services/calendarService';
import Header from '../layouts/header';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ReactComponent as Union } from '../assets/union.svg';
import Bookmark from './bookmark';
import SearchResult from '../models/SearchResult';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  position: 'absolute',
  width: '360px',
  height: '80px',
  top: '0px',
  background: '#F7F8F9',
}));

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    getMetaData()
      .then((metadata) => {
        this.setState({
          dcNbrList: metadata.dcNbrList,
          calStartDate: metadata.startDate,
          calEndDate: metadata.endData,
        });
      })
      .catch((err) => {
        console.error(err);
        this.state.showMsgCallback(
          'Something went wrong, please refresh the page.'
        );
      });

    this.searchCallback = (
      showItemSearch,
      searchedItemNbrList,
      searchedVendorNbrList,
      searchedDCNbr,
      searchResult,
      searchedDcNbrList
    ) => {
      this.setState({
        showItemSearch,
        searchedItemNbrList,
        searchedVendorNbrList,
        searchedDCNbr,
        searchResult,
        searchedDcNbrList,
      });
    };

    const emptySearchResult = new SearchResult({
      itemNbr: 0,
      itemDesc: 'Not Available',
      item2Desc: 'Not Available',
      legacyItemNbr: 0,
      nineDigitVendorNbr: 'Not Available',
      upcNbr: 0,
      vendorName: 'Not Available',
      vendorNbr: 0,
      category: 0
    });

    this.itemSearchDcNavCallback = (newDC, currentItemIndex) => {
      searchItems(this.state.searchedItemNbrList, newDC)
        .then((searchResults) => {
          if (searchResults.length > 0) {
            this.state.searchCallback(
              this.state.showItemSearch,
              this.state.searchedItemNbrList,
              this.state.searchedVendorNbrList,
              newDC,
              searchResults,
              this.state.searchedDcNbrList
            );
          } else {
            this.state.searchCallback(
              this.state.showItemSearch,
              this.state.searchedItemNbrList,
              this.state.searchedVendorNbrList,
              newDC,
              [emptySearchResult],
              this.state.searchedDcNbrList
            );
          }
          this.state.selectItemCallback(
            this.state.searchResult[currentItemIndex]
          );
          this.state.selectSavedSearchCallback(null);
        })
        .catch((err) => {
          console.error(err);
          this.state.showMsgCallback('Something Went Wrong!');
        });
    };

    this.vendorSearchDcNavCallback = (newDC, currentItemIndex) => {
      searchItemsForVendors(this.state.searchedVendorNbrList, newDC)
        .then((searchResults) => {
          let { itemInfo, validVendorNbrs } = searchResults;
          if (itemInfo.length > 0) {
            this.state.searchCallback(
              this.state.showItemSearch,
              this.state.searchedItemNbrList,
              this.state.searchedVendorNbrList,
              newDC,
              itemInfo,
              this.state.searchedDcNbrList
            );
          } else {
            this.state.searchCallback(
              this.state.showItemSearch,
              this.state.searchedItemNbrList,
              this.state.searchedVendorNbrList,
              newDC,
              [emptySearchResult],
              this.state.searchedDcNbrList
            );
          }
          this.state.selectItemCallback(
            this.state.searchResult[currentItemIndex]
          );
          this.state.selectSavedSearchCallback(null);
        })
        .catch((err) => {
          console.error(err);
          this.state.showMsgCallback('Something Went Wrong!');
        });
    };

    this.getSelectedItemIndexCallback = () => {
      return this.state.searchResult.findIndex(
        (item) => item.itemNbr === this.state.selectedItem.itemNbr
      );
    };

    this.selectItemCallback = (searchResult) => {
      this.setState({ selectedItem: searchResult });
    };

    this.firstDateChangeCallback = (date) => {
      this.setState({ firstDate: date });
    };

    this.setShowDurationSelector = (show) => {
      this.setState({ showDurationSelector: show });
    };

    this.showMsgCallback = (msg) => {
      this.setState({ message: msg });
    };

    this.selectSavedSearchCallback = (savedSearch) => {
      this.setState({
        selectedSavedSearch: savedSearch,
      });
    };
    this.setNeedToFetchSavedSearch = () => {
      this.setState({
        needToFetchSavedSearch: true,
      });
    };
    this.fetchSavedSearchCallBack = (savedSearchList) => {
      this.setState({
        savedSearchList: savedSearchList,
        needToFetchSavedSearch: false,
      });
    };

    this.state = {
      // metadata
      dcNbrList: [],
      calStartDate: null,
      calEndDate: null,
      // search
      showItemSearch: true,
      searchedItemNbrList: [],
      searchedVendorNbrList: [],
      searchedDcNbrList: [],
      searchedDCNbr: null,
      searchResult: null,
      searchCallback: this.searchCallback,
      itemSearchDcNavCallback: this.itemSearchDcNavCallback,
      vendorSearchDcNavCallback: this.vendorSearchDcNavCallback,
      // savedSearch
      selectedSavedSearch: null,
      showHideSidenav: this.showHideSidenav,
      savedSearchList: [],
      needToFetchSavedSearch: false,
      fetchSavedSearchCallBack: this.fetchSavedSearchCallBack,
      setNeedToFetchSavedSearch: this.setNeedToFetchSavedSearch,
      selectSavedSearchCallback: this.selectSavedSearchCallback,
      // selected item
      selectedItem: null,
      selectItemCallback: this.selectItemCallback,
      getSelectedItemIndexCallback: this.getSelectedItemIndexCallback,
      // calendar
      firstDate: getCurrentFirstDate(), // must be a valid Saturday
      firstDateChangeCallback: this.firstDateChangeCallback,
      // tab
      showDurationSelector: true,
      setShowDurationSelector: this.setShowDurationSelector,
      // snackbar
      message: null,
      showMsgCallback: this.showMsgCallback,
      showDrawer: true,
      showBookmarkDrawer: false,
      // week/compareweek view
      switchSearch: 'week',
      setSwitchSearch: this.setSwitchSearch,
      itemAvailable: true,
      setItemAvailable: this.setItemAvailable,
    };

    this.showHideSidenav = this.showHideSidenav.bind(this);
    this.setSwitchSearch = this.setSwitchSearch.bind(this);
    this.resetElement = React.createRef();
    this.setItemAvailable = this.setItemAvailable.bind(this);
  }

  setSwitchSearch = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        switchSearch: value,
      };
    });
  };

  setItemAvailable = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        itemAvailable: value,
      };
    });
  };

  showHideSidenav = () => {
    if (this.state.showBookmarkDrawer) {
      this.showHideBookmarkDrawer();
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        showDrawer: !prevState.showDrawer,
      };
    });
  };

  showHideBookmarkDrawer = () => {
    if (this.state.showDrawer) {
      this.showHideSidenav();
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        showBookmarkDrawer: !prevState.showBookmarkDrawer,
      };
    });
  };

  handleMsgClose() {
    this.setState({ message: null });
  }

  handleReset = () => {
    this.setState({
      showItemSearch: true,
      searchedItemNbrList: [],
      searchedVendorNbrList: [],
      searchedDcNbrList: [],
      searchResult: null,
      selectedItem: null,
    });
    this.resetElement.current.resetSearch();
  };

  render() {
    const { classes, Autocomp } = this.props;
    return (
      <UserContext.Consumer>
        {(context) => (
          <Context.Provider value={{ ...this.state, user: context.user }}>
            <div className={classes.root}>
              <div container="true">
                <div className={impstyles.RightGrid}>
                  <Header
                    user={context.user}
                    authButtonMethod={context.logout}
                    showHideSidenav={this.showHideSidenav}
                    showHideBookmarkDrawer={this.showHideBookmarkDrawer}
                  />

                  <RightGrid />
                </div>
                <div>
                  <Drawer
                    sx={{
                      maxWidth: 387,
                      flexShrink: 1,
                      '& .MuiDrawer-paper': {
                        width: 387,
                        height: '100vh',
                        top: 0,
                        background: '#FFFFFF',
                        overflow: 'hidden',
                      },
                    }}
                    ModalProps={{ onBackdropClick: this.showHideSidenav }}
                    anchor="right"
                    open={this.state.showDrawer}
                  >
                    <div className={impstyles.drawerHeader}>
                      <div className={impstyles.unionIcon}>
                        <Union />
                      </div>
                      <div className={impstyles.headerTextContainer}>
                        <h3 className={impstyles.headerTextCss}>
                          What are you looking for?
                        </h3>
                        <h6 className={impstyles.headerSubtextCss}>
                          Search for specific items
                        </h6>
                      </div>{' '}
                      <div>
                        <span
                          role="tab"
                          tabIndex="-1"
                          data-testid="resetButton"
                          id="resetButton"
                          className={impstyles.resetButton}
                          onClick={this.handleReset}
                        >
                          Reset
                        </span>
                        <div className={impstyles.spacer} />
                      </div>
                    </div>
                    <SideNav resetReference={this.resetElement} />
                  </Drawer>
                  <Bookmark
                    showBookmarkDrawer={this.state.showBookmarkDrawer}
                    showHideBookmarkDrawer={this.showHideBookmarkDrawer}
                  />
                </div>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={this.state.message}
                autoHideDuration={6000}
                onClose={this.handleMsgClose.bind(this)}
                message={this.state.message}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleMsgClose.bind(this)}
                      data-testid="iconButton"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </div>
          </Context.Provider>
        )}
      </UserContext.Consumer>
    );
  }
}

Dashboard.contextType = UserContext;

export default withStyles(styles)(Dashboard);
