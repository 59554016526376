import React, { useState, useContext, useEffect } from "react";
import { useMyContext } from '../context/Context';
import { getItemDetails } from "../services/apiService";
// Material UI
import { AppBar } from "@material-ui/core";
// Styles
import styles from "./../styles/HeaderFooterStyles.module.scss";

import { Helmet } from "react-helmet";
// icons
import { ReactComponent as Bookmark } from "../assets/header_icons/Bookmark.svg";
import { ReactComponent as Calendar } from "../assets/header_icons/Calendar.svg";
import { ReactComponent as Chevron } from "../assets/header_icons/Chevron.svg";
import { ReactComponent as Download } from "../assets/header_icons/Download.svg";
import { ReactComponent as Settings } from "../assets/header_icons/Settings.svg";
import { ReactComponent as History } from "../assets/header_icons/History.svg";
import { ReactComponent as List } from "../assets/header_icons/List.svg";
// Components
import { NavigationButton } from "../components/common/NavigationButton";
import DatePicker from "../components/datePicker";
import HeaderMenu from "./headerMenu";
import { DownloadAlert } from "../components/common/alert";
import DownloadDialog from "../components/download/DownloadDialog";
import Popover from '@mui/material/Popover';
import { styled } from '@mui/system';
import Item from '../models/Item';

const StyledPopOver = styled((props) => (
  <Popover
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '336px',
    maxWidth: '336px',
    borderRadius: '0 !important',
    boxShadow: '0px 8px 24px rgba(0, 39, 81, 0.15)',
    marginTop: '4px',
  },
}));

const Header = ({ showHideSidenav, showHideBookmarkDrawer }) => {
  const context = useMyContext();
  // local state
  const [selectedDate, handleDateChange] = useState(new Date());
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDownloading, setDownloading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popOverId = open ? 'simple-popover' : undefined;

  // Helmet
  const mainTitle =
    window._env_.ENV === "Prod"
      ? "Home | DDIR"
      : `Home | DDIR (${window._env_.ENV})`;

  const emptyItemDetails = new Item(
    {
      "itemNbr": context.selectedItem ? context.selectedItem.itemNbr : 'not available',
      "itemDesc": context.selectedItem ? context.selectedItem.itemDesc : 'not available',
      "upcNbr": context.selectedItem ? context.selectedItem.upcNbr : 0,
      "ossOnHandCaseQty": "N/A",
      "vendorName": context.selectedItem ? context.selectedItem.vendorName : 'not available',
      "category": context.selectedItem ? context.selectedItem.category : 'N/A'
    });
  // Get Item Details
  useEffect(() => {
    if (context.selectedItem && context.searchedDCNbr) {
      getItemDetails(
        context.selectedItem.itemNbr,
        context.selectedItem.legacyItemNbr,
        context.searchedDCNbr,
      ).then((result) => {
        setCurrentSelectedItem(result);
        context.setItemAvailable(true);
      }).catch((err) => {
        setCurrentSelectedItem(emptyItemDetails);
        context.setItemAvailable(false);
      })
    } else {
      setCurrentSelectedItem(null);
    }
  }, [context.selectedItem, context.searchedDCNbr])

  // Keypress scan selected items
  useEffect(() => {
    document.addEventListener('keydown', detectKeyDown);

    return () => {
      document.removeEventListener("keydown", detectKeyDown)
    }
  }, [context.selectedItem, context.switchSearch, context.itemAvailable])

  const detectKeyDown = (e) => {
    const inputTypes = ['input', 'textarea'];
    if (document.activeElement && inputTypes.includes(document.activeElement.tagName.toLowerCase())) {
      return;
    }
    if (e.key === "ArrowLeft" && context.selectedItem) {
      e.preventDefault();
      searchLeftHandler();
    }
    if (e.key === "ArrowRight" && context.selectedItem) {
      e.preventDefault();
      searchRightHandler();
    }
    if (e.key === "ArrowUp" && context.selectedItem) {
      e.preventDefault();
      searchUpHandler();
    }
    if (e.key === "ArrowDown" && context.selectedItem) {
      e.preventDefault();
      searchDownHandler();
    }
    if (e.shiftKey && e.key === "?" && context.selectedItem) {
      e.preventDefault();
      handleView();
    }
    if (e.key === "B" || e.key === "b") {
      e.preventDefault();
      showHideBookmarkDrawer();
    }
    if (e.key === "S" || e.key === "s") {
      e.preventDefault();
      showHideSidenav();
    }
    if (e.key === "D" || e.key === "d") {
      e.preventDefault();
      onClickDownload();
    }
  }

  // Helper Functions
  const searchRightHandler = () => {
    let currentIndex = context.getSelectedItemIndexCallback();
    if (currentIndex < context.searchResult.length - 1) {
      context.selectItemCallback(context.searchResult[currentIndex + 1]);
    } else {
      context.selectItemCallback(context.searchResult[0]);
    }
  }

  const searchLeftHandler = () => {
    let currentIndex = context.getSelectedItemIndexCallback();
    if (currentIndex > 0) {
      context.selectItemCallback(context.searchResult[currentIndex - 1]);
    } else {
      context.selectItemCallback(context.searchResult[context.searchResult.length - 1]);
    }
  }

  const searchUpHandler = () => {
    if (context.searchedDcNbrList.length > 1) {
      let currentDcIndex = context.searchedDcNbrList.indexOf(context.searchedDCNbr);
      const currentItemIndex = context.searchResult.findIndex((item) => item.itemNbr === context.selectedItem.itemNbr);
      let nextDC = "";
      if (currentDcIndex < context.searchedDcNbrList.length - 1) {
        nextDC = context.searchedDcNbrList[currentDcIndex + 1];
      } else {
        nextDC = context.searchedDcNbrList[0];
      }
      if (context.showItemSearch) {
        context.itemSearchDcNavCallback(nextDC, currentItemIndex);
      } else {
        context.vendorSearchDcNavCallback(nextDC, currentItemIndex);
      }
    }
  }

  const searchDownHandler = () => {
    if (context.searchedDcNbrList.length > 1) {
      const currentDcIndex = context.searchedDcNbrList.indexOf(context.searchedDCNbr);
      const currentItemIndex = context.searchResult.findIndex((item) => item.itemNbr === context.selectedItem.itemNbr);
      let prevDC = "";
      if (currentDcIndex > 0) {
        prevDC = context.searchedDcNbrList[currentDcIndex - 1];
      } else {
        prevDC = context.searchedDcNbrList[context.searchedDcNbrList.length - 1];
      }
      if (context.showItemSearch) {
        context.itemSearchDcNavCallback(prevDC, currentItemIndex);
      } else {
        context.vendorSearchDcNavCallback(prevDC, currentItemIndex);
      }
    }
  }

  // Download Helper Functions

  const handleDownloading = (isDownloading) => {
    if (isDownloading) {
      setDownloading(true);
      setShowDialog(false);
      setShowAlert(true);
    } else {
      setDownloading(false);
      setShowAlert(false);
    }
  }

  const onClickDownload = () => {
    if (context.searchResult && context.searchResult.length) {
      setShowDialog(true);
      setOpenDropdown(false);
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }
  const handleView = () => {
    if (context.switchSearch === 'week' && context.itemAvailable) {
      context.setSwitchSearch('compare');
      context.setShowDurationSelector(false);
    } else if(context.switchSearch === 'compare') {
      context.setSwitchSearch('week');
      context.setShowDurationSelector(true);
    }
  }

  // Render Item Info State
  if (currentSelectedItem && context.searchedDCNbr) {
    return (
      <AppBar position="static" color="inherit" className={styles.header}>
        <Helmet>
          <title>{mainTitle}</title>
        </Helmet>
        {/* Current Item Display */}
        <div className={styles.itemDisplay}>
          <div className={styles.itemContent}>
            <img
              data-testid="header-img"
              src={
                currentSelectedItem && currentSelectedItem.itemImgUrl
                  ? currentSelectedItem.itemImgUrl
                  : `https://scene7.samsclub.com/is/image/samsclub/0040640600219`
              }
              className={styles.itemImage}
              alt="current item"
            />
            <div className={styles.itemInfoContainer}>
              <span className={styles.itemNumber} id="current-item-number">
                Item {currentSelectedItem.itemNbr}
              </span>
              <span
                className={styles.itemDescription}
                id="current-item-description"
              >
                {currentSelectedItem.itemDesc} --&nbsp;
                  <span className={currentSelectedItem.ossOnHandCaseQty > 0 && styles.outStorageText}>
                    Out Storage: {currentSelectedItem.hasOwnProperty('ossOnHandCaseQty') ? currentSelectedItem.ossOnHandCaseQty.toLocaleString() : '--'}
                  </span>
              </span>
              <span
                className={styles.itemDetail}
              >
                Category : {currentSelectedItem.category} {'\u00A0'} Vendor : {currentSelectedItem.vendorNbr} - {currentSelectedItem.vendorName}
              </span>
            </div>
          </div>
          {/* Item Nav */}
          <div className={styles.itemNav} id="item-navigation">
            <NavigationButton
              title="Previous item (Keyboard: Arrow left)"
              id="prev-item"
              tipPosition="right"
              type="nav"
              clickHandler={() => searchLeftHandler()}
            >
              <Chevron className={styles.chevLeft} />
            </NavigationButton>
            <NavigationButton
              title="Next item (Keyboard: Arrow right)"
              id="next-item"
              tipPosition="right"
              type="nav"
              clickHandler={() => searchRightHandler()}
            >
              <Chevron className={styles.chevRight} />
            </NavigationButton>
            {/* DC Nav */}
            <div className={styles.dcNav}>
              <NavigationButton
                title="Previous DC (Keyboard: Arrow down)"
                id="prev-DC"
                tipPosition="right"
                type="nav"
                clickHandler={() => searchDownHandler()}
              >
                <Chevron className={styles.chevDown} />
              </NavigationButton>
              <span className={styles.dcNumber}>DC {context.searchedDCNbr}</span>
              <NavigationButton
                title="Next DC (Keyboard: Arrow up)"
                id="next-DC"
                tipPosition="right"
                type="nav"
                clickHandler={() => searchUpHandler()}
              >
                <Chevron className={styles.chevUp} />
              </NavigationButton>
            </div>
          </div>
        </div>
        <div className={styles.rightToolbar}>
          <div className={styles.historyNav}>
            <NavigationButton
              title={context.switchSearch === 'week' ? "Historic comparison (Keyboard: ‘?’)" : "Toggle view (Keyboard: ‘?’)"}
              id="compare-view"
              tipPosition="right"
              type="nav"
              clickHandler={handleView}
              disabled={!context.itemAvailable && context.switchSearch === 'week'}
            >
              {context.switchSearch === 'week' ? <History className={context.itemAvailable ? "" : styles.iconDisabled} /> : <List className={context.itemAvailable ? "" : styles.iconDisabled} />}
            </NavigationButton>
          </div>
          {/* calendar box */}
          <div id="date-component" className={styles.calendarPlaceholder} aria-describedby={popOverId} onClick={handleClick}>{context.firstDate.format("MMMM YYYY")}</div>
          <StyledPopOver
            data-testid="pop-over"
            id={popOverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DatePicker handleClose={handleClose} />
          </StyledPopOver>
          {/* icons */}
          <div className={styles.iconButtonsContainer}>
            <NavigationButton
              data-testid="nav-bookmark-btn"
              clickHandler={showHideBookmarkDrawer}
              title="Bookmarks (Keyboard: 'B')"
              id="save-button"
              tipPosition="left"
              type="icon"
            >
              <Bookmark className={styles.bookmarkIcon} />
            </NavigationButton>
            <NavigationButton
              title="Download set (Keyboard: 'D')"
              id="download-btn"
              tipPosition="left"
              type="icon"
              clickHandler={onClickDownload}
            >
              <Download className={styles.kebabIcon} />
            </NavigationButton>
            <NavigationButton
              data-testid="nav-search-btn"
              clickHandler={showHideSidenav}
              title="Search and filter items (Keyboard: 'S')"
              id="search-button"
              tipPosition="left"
              type="icon"
            >
              <Settings className={styles.unionIcon} />
            </NavigationButton>
            {openDropdown && <HeaderMenu setOpenDropdown={setOpenDropdown} onClickDownload={onClickDownload} />}
          </div>
        </div>
        <DownloadDialog data-testid="download-dialog" open={showDialog} handleClose={handleDialogClose} handleDownloading={handleDownloading} />
        <DownloadAlert data-testid="download-alert" open={showAlert} handleClose={() => setShowAlert(false)} />
      </AppBar>
    );
  } else {
    // No Item State
    return (
      <AppBar position="static" color="inherit" className={styles.header}>
        <Helmet>
          <title>{mainTitle}</title>
        </Helmet>
        <div className={styles.leftSpacer} />
        <div className={styles.rightToolbar}>
          {/* icons */}
          <div className={styles.iconButtonsContainer}>
            <NavigationButton
              clickHandler={showHideBookmarkDrawer}
              title="Bookmarks (Keyboard: 'B')"
              id="save-button"
              tipPosition="left"
              type="icon"
            >
              <Bookmark className={styles.bookmarkIcon} />
            </NavigationButton>
            <NavigationButton
              clickHandler={showHideSidenav}
              title="Search and filter items (Keyboard: 'S')"
              id="search-button"
              tipPosition="left"
              type="icon"
            >
              <Settings className={styles.unionIcon} />
            </NavigationButton>
          </div>
        </div>
      </AppBar>
    );
  }
};

export default Header;
