import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Search  from './search';
import SearchResult  from './searchResult';
import Context from '../context/Context';
import { Divider } from '@material-ui/core';
import BulkDownload from './download/BulkDownload';
import searchStyles from '../styles/SearchStyles.module.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        width: 350,
        margin: theme.spacing(2),
        textAlign: 'left',
    },
    SearchResultpaper: {
        width: 350,
        // margin: theme.spacing(2),
        margin: 0,
        textAlign: 'left',
        padding: 0
    },
});

class SideNav extends Component {
    
    render() {
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {context => (
            <div className={searchStyles.sideNav}>
                        {/* <Paper className={classes.paper} style={{boxShadow:"none"}}> */}
                            <Search dcList={context.dcNbrList} selectedSavedSearch={context.selectedSavedSearch} showHideSidenav={context.showHideSidenav} ref={this.props.resetReference} />
                        {/* </Paper> */}
                        
                        {context.searchResult && context.searchResult.length > 0 && <SearchResult />}
                        {/* <>  */}
                        {/* <Paper className={classes.SearchResultpaper} style={{boxShadow:"none", marginLeft:"0px"}}> */}
                           
                        {/* </Paper> */}
                        {/* <Paper className={classes.paper} style={{boxShadow:"none"}}>
                            <BulkDownload/>
                        </Paper> */}
                       {/* </>} */}
            </div>)}
            </Context.Consumer>
            
        );
    }
}
SideNav.contextType = Context;
export default withStyles(styles)(SideNav);