import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useMyContext } from '../../context/Context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as DcCheckbox } from "../../assets/checkbox_icons/Checkbox_dc.svg";
import { ReactComponent as DcCheckboxChecked } from "../../assets/checkbox_icons/Checkbox_dc_checked.svg";
import { downloadInventoryData } from '../../services/downloadService';
import FormGroup from '@material-ui/core/FormGroup';
import impstyles from '../../styles/DownloadComponentStyles.module.scss';
import SelectDcErrorMessage from './SelectDcErrorMessage';
import moment from 'moment';

const MuiDownloadDialog = withStyles((theme) => ({
    root: {
      borderRadius: '8px',
    },
    paper: {
      maxWidth: '900px',
      padding: '28px 36px',
    },
  }))(Dialog);

export default function DownloadDialog(props) {
  var _ = require('lodash');
  const allDCsId = "All DC's";

  const context = useMyContext();

  let dcObj = context.dcNbrList.map((dcNum) => {
    return { id: dcNum, checked: false };
  });
  dcObj.unshift({ id: allDCsId, checked: false });

  const [selectedDCs, setSelectedDCs] = useState([]);
  const [showError, setShowError] = useState(false);
  const [dcObjects, setDcObjects] = useState(dcObj);
  const [dateString, setDateString] = useState("");
  const selectDcErrorMessage = 'Please select a DC';
  // const [isDownloading, setDownloading] = useState(false);

  useEffect(() => {
    if (context.searchedDcNbrList) {
      let dcValues = dcObjects;
      context.searchedDcNbrList.map((dc) => {
        if ((dc + '').length !== 4) {
          dc = dc.substring(1);
        }
        const selectedDCIndex = dcObjects.findIndex(
          (data) => data.id === parseInt(dc)
        );
        if (selectedDCIndex !== -1) {
          dcValues[selectedDCIndex].checked = true;
        }
      });
      setDcObjects(dcValues);
      updateSelectedDCs();
    }
    setDateString(moment().format("MMM Do, YYYY"));
  }, [context.searchedDcNbrList]);

  // theme

  //   const useStyles = makeStyles

  const DialogTitle = withStyles((theme) => ({
    root: {
      padding: '0px',
      marginBottom: '5px',
    },
  }))((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: '0px',
      overflow: 'hidden',
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      marginTop: '10px',
      padding: 0,
    },
  }))(MuiDialogActions);

  const BlueCheckbox = withStyles({
    root: {
      color: '#0067A0',
      '&$checked': {
        color: '#0071E9'
      }
    },
  })(Checkbox);

  const CustomButton = withStyles({
    root: {
      width: '168px',
      height: '48px',
      background: '#0071E9',
      borderRadius: '4px',
      marginLeft: '15px'
    },
    label: {
      fontFamily: 'canada-type-gibson',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      textTransform: 'capitalize'
    },
  })(Button);

  const CustomOutlineButton = withStyles({
    root: {
      width: '168px',
      height: '48px',
      color: '#0071E9',
      border: 'solid 2px #0071E9',
      borderRadius: '4px'
    },
    label: {
      fontFamily: 'canada-type-gibson',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      textTransform: 'capitalize'
    },
  })(Button);

  // functions
  const handleClose = () => {
    setDefaults();
    props.handleClose();
  };

  const setDefaults = () => {
    setSelectedDCs([]);
    setDcObjects(dcObj);
    setShowError(false);
  };

  const handleDownload = async () => {
    if (selectedDCs.length === 0) {
      setShowError(true);
    } else {
      //setDownloading(true);
      props.handleDownloading(true);

      await downloadInventoryData(
        context.searchResult.map((item) => item.legacyItemNbr),
        selectedDCs
      );
      //setDownloading(false);
      setDefaults();
      props.handleDownloading(false);
    }
  };

  const clubHandleChange = (event) => {
    if (event.target.value === allDCsId) {
      for (var dc of dcObjects) {
        dc.checked = event.target.checked;
      }
    } else {
      _.set(
        _.find(dcObjects, { id: parseInt(event.target.value) }),
        'checked',
        event.target.checked
      );
    }
    updateSelectedDCs();
  };

  const updateSelectedDCs = () => {
    let _selectedDCs = [];
    dcObjects.forEach((dcObj) => {
      if (dcObj.checked && dcObj.id !== allDCsId) {
        _selectedDCs.push(dcObj.id);
      }
    });
    setSelectedDCs(_selectedDCs);
    setShowError(false);
  };
  // var and consts
  return (
    <MuiDownloadDialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={'md'}
    >
      <DialogTitle id="customized-dialog-title">
        {showError ? (
          <SelectDcErrorMessage errorMessage={selectDcErrorMessage} />
        ) : (
          <></>
        )}
        <span className={impstyles.dialogTitle}>
          Download set - select DC's
        </span>
        <br />
        <span className={impstyles.dialogSubtitle}>
          Retrieving data for {dateString} &#40;one week prior + two weeks into
          the future&#41;
        </span>
      </DialogTitle>
      <DialogContent>
        <div className={impstyles.DcListBorder}>
          <FormControl>
            <FormGroup style={{ flexDirection: 'row' }}>
              {dcObjects.map((cl) => {
                return (
                  <div
                    className={impstyles.DcFormControlLabelBorder}
                    key={cl.id}
                  >
                    <FormControlLabel
                      icon={<span className={{ color: '#0060C6' }} />}
                      className={impstyles.downloadFormControlLabel}
                      key={cl.id}
                      control={
                        <BlueCheckbox
                          color="primary"
                          checked={cl.checked}
                          onChange={clubHandleChange}
                          value={cl.id}
                          control={<Checkbox icon={<DcCheckbox />} checkedIcon={<DcCheckboxChecked />} name="DcCheck" />}
                        />
                      }
                      label={
                        <Typography className={impstyles.formControlLabelText}>
                          {cl.id}
                        </Typography>
                      }
                      //   label={cl.id}
                    />
                  </div>
                );
              })}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions className={impstyles.dialogActions}>
        <CustomOutlineButton
          id="CancelButton"
          variant="outlined"
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </CustomOutlineButton>
        <div className={impstyles.buttonSpacer} />
        <CustomButton
          id="DownloadButton"
          variant="contained"
          color="primary"
          onClick={handleDownload}
        >
          Download
        </CustomButton>
      </DialogActions>
    </MuiDownloadDialog>
  );
}
