import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomButton = withStyles({
    // remove text transform
    root: {
        textTransform: 'none'
    }
})(Button);

const LoadingIndicator = props =>{
 return <CircularProgress size={24} 
        style = {{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12}}
    />
}

const PrimaryButton = props => {
    return (
    <CustomButton
        fullWidth={true}
        variant="contained"
        color="primary" 
        disabled={props.loading}
        {...props}
        >
        {props.children}
        {props.loading && <LoadingIndicator />}
    </CustomButton>);
};

const SecondaryButton = props => {
    return (
    <CustomButton
        fullWidth={true}
        variant="outlined"
        color="primary" 
        {...props}
        >
        {props.children}
        {props.loading && <LoadingIndicator />}
    </CustomButton>);
};

const BlueButton = withStyles({
    root: {
      width: '143px',
      height: '40px',
      background: '#0071E9',
      color:  '#FFFFFF',
      borderRadius: '3px',
      "&:disabled": {
        background: '#F1F4F6',
        color: '#607480'
      },
      "&:hover": {
        color: '#F1F4F6'
      },
      disabled:{},
    },
    label: {
      fontFamily: 'canada-type-gibson',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      textTransform: 'capitalize'
    },
  
  })(Button);
  
  const MinimalButton = withStyles({
    root: {
      width: '143px',
      height: '40px',
      background: '#E7EDF6',
      color:  '#0071E9',
      borderRadius: '3px',
    },
    label: {
      fontFamily: 'canada-type-gibson',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      textTransform: 'capitalize'
    },
  })(Button);

export {PrimaryButton, SecondaryButton, LoadingIndicator, BlueButton, MinimalButton}