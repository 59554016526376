import React, { Component } from 'react';
import "../styles/invenTable.css"
import Context from '../context/Context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { searchDdirTrend } from '../services/apiService';
import DataTableComponent from './DataTableComponent';

class CompareWeek extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: [{
                date: ["9/7", "9/14", "9/21", "9/28", "10/05", "10/12", "10/19", "10/26", "11/03", "11/10"],
                week: "33-43", year: 2019,
                data: {
                    demand: [1350, 1550, 1678, 1435, 1590, 666, 878, 1590, 666, 878],
                    ordered: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    received: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    inventory: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    sales: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878]
                }
            },
            {
                date: ["9/7", "9/14", "9/21", "9/28", "10/05", "10/12", "10/19", "10/26", "11/03", "11/10"],
                week: "33-43", year: 2020,
                data: {
                    demand: [1345, 1550, 1700, 1435, 1567, 1666, 878, 1590, 666, 878],
                    ordered: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    received: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    inventory: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    sales: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878]
                }
            },
            {
                date: ["9/7", "9/14", "9/21", "9/28", "10/05", "10/12", "10/19", "10/26", "11/03", "11/10"],
                week: "33-43", year: 2020,
                data: {
                    demand: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    ordered: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    received: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    inventory: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878],
                    sales: [1345, 1550, 1678, 1435, 1567, 1666, 878, 1590, 666, 878]
                }
            },

            ],
            anchorEl: null,
            isLoading: false,
        }
        this.currentSelectedItem = null;
        this.currentDCNbr = null;
    }
    getTable(itemNbr) {
        this.setState({ table: [], isLoading: true });
        searchDdirTrend(
            this.context.selectedItem.itemNbr,
            this.context.selectedItem.legacyItemNbr,
            this.context.searchedDCNbr
        ).then(data => {
            console.log("Trend Info", data);
            this.currentSelectedItem = this.context.selectedItem;
            this.currentDCNbr = this.context.searchedDCNbr;
            this.setState({ table: data, isLoading: false });
        }).catch(err => {
            console.error(err);
            this.currentSelectedItem = this.context.selectedItem;
            this.currentDCNbr = this.context.searchedDCNbr;
            this.setState({ table: [], isLoading: false });
            this.context.showMsgCallback('TrendData Failed');
        });
        //Get TABLE POST call

    }
    loadTableIfNeeded() {
        const { selectedItem, searchedDCNbr } = this.context;
        if (!this.state.isLoading &&
            selectedItem && searchedDCNbr &&
            (this.currentSelectedItem !== selectedItem || this.currentDCNbr !== searchedDCNbr))
            this.getTable(selectedItem.itemNbr);
    }


    render() {
        this.loadTableIfNeeded();
        return (
            <Context.Consumer>
                {context => (
                    <div>
                        {!this.state.isLoading ?
                            <div>
                                {this.state.table.map((table, idx) => (
                                        <React.Fragment key={idx}>
                                    <div style={{
                                        background: '#F1F5FB', padding: '0px 36px', fontWeight: '500', fontSize: '16px', lineHeight: '22px', color: '#00172F', marginBottom: '1.5px'
                                    }}>Week {table.week}, <span style={{ color: '#33577D' }}>{table.year}</span></div>
                                    <DataTableComponent
                                        origin="compare"
                                        dc={context.searchedDCNbr}
                                        itemNum={context.selectedItem ? context.selectedItem.itemNbr : null}
                                        table={[table]}
                                    />
                                        </React.Fragment>
                                    ))}
                            </div> :
                            <CircularProgress size={100} style={{ margin: "10% 45%" }} />}
                    </div>
                )}
            </Context.Consumer>);
    }
}
CompareWeek.contextType = Context;
export default CompareWeek;