export default class TrendTable {
    constructor(data) {
        const table = [];
        if(data!== undefined && data['lyTrendWeekList'].length===22 && data['tyTrendWeekList'].length===11){
            table.push(this.mapResponseData(data['lyTrendWeekList'].slice(0, 11)));
            table.push(this.mapResponseData(data['lyTrendWeekList'].slice(11)));
            table.push(this.mapResponseData(data['tyTrendWeekList']));
        }
        return table;
        }

        mapResponseData = (tableArray) => {
            const TrendData = {
                    date : [],
                    week : {},
                    year : {},
                    data : {demand:[], ordered:[], received:[], inventory:[], sales:[]},
                };
            TrendData['week'] = tableArray[0]['wmtWk'].toString() + "-" + tableArray[10]['wmtWk'].toString();
            TrendData['year'] = tableArray[10]['wmtYear'];
            tableArray.forEach(row => {
                TrendData['date'].push(row['weekStartDt']);
                TrendData['data']['demand'].push(row['demandQty']);
                TrendData['data']['ordered'].push(row['orderedQty']);
                TrendData['data']['received'].push(row['receivedQty']);
                TrendData['data']['inventory'].push(row['inventoryQty']);
                TrendData['data']['sales'].push(row['salesQty']);
            })
            return TrendData;
        }
    


    static getDummyObj() {
        return new TrendTable([
                {
                "date": ["Sat 10/17", "Sun 10/18", "Mon 10/19", "Tues 10/20", "Wed 10/21", "Thur 10/22", "Fri 10/23"],
                "week": 37,
                "year": 2020,
                "data": {
                    "demand" : [350,555,678,432,590,666,878],
                    "ordered" : [345,555,678,432,567,666,878],
                    "received" : [345,555,678,432,567,666,878],
                    "inventory" : [345,555,678,432,567,666,878],
                    "sales" : [345,555,678,432,567,666,878]
                },
                }
            ])
    }
}