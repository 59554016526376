import React from "react";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";

export default function HelperText(props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <RemoveCircleOutlinedIcon style={{ fontSize: "16px" }} />
      <span style={{ paddingLeft: "5px" }}>{props.text}</span>
    </div>
  );
}
