import React from "react";
import styles from "./../styles/HeaderFooterStyles.module.scss";

const HeaderMenu = (props) => {

  return (
    <ul
      className={styles.dropdown}
      id="dropdown-menu"
      role="menu"
      aria-labelledby="menu-open"
      onMouseLeave={() => props.setOpenDropdown(false)}
    >
      <li className={styles.menuItem} role="menuitem" id="downloadLink" onClick={() => props.onClickDownload()}>
        Download set
      </li>
      <div className={styles.dividerLine} />
      <li className={styles.menuItem} role="menuitem" id="aboutLink">
        About DDIR
      </li>
      <div className={styles.dividerLine} />
      <li className={styles.menuItem} role="menuitem" id="helpLink">
        Help / Tutorials
      </li>
    </ul>
  );
};

export default HeaderMenu;