import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ItemComponent from '../components/ItemCard/itemComponent';
import SwitchView from './switchViewComponent';
import NoResult from './noResult';
import Context from '../context/Context';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  Tablepaper: {
    minWidth: 354,
    textAlign: "left",
  },
});


class RightGrid extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Context.Consumer>
        {context => (<div>
          {context.selectedItem && context.searchResult && context.searchResult.length>0 ?
            (<>
            {/* <ItemComponent /> */}
            <div className = {classes.Tablepaper}>
              <SwitchView/>
            </div>
            </>)
            :
            <NoResult />}
        </div>)}
      </Context.Consumer>);
  }
}
RightGrid.contextType = Context;
export default withStyles(styles)(RightGrid);