import React, { useState, useEffect, useRef } from 'react';
import { getSavedSearch, deleteSavedSearch, searchItems, searchItemsForVendors, addSavedSearch } from '../services/apiService';
import { useMyContext } from '../context/Context';
import SavedSearch from '../models/SavedSearch';
import BookmarkTitle from './bookmarkTitle';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import styles from "../styles/bookmark.module.scss";
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { SecondaryButton } from '../components/common/customButton';
import { CommonAlert, AlertIconType } from './common/alert';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import HelperText from './common/errorHelperText';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    padding: theme.spacing(2, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    background: '#F1F4F6',
    fontFamily: "canada-type-gibson",
}));

const Bookmark = ({ showBookmarkDrawer, showHideBookmarkDrawer }) => {
    const context = useMyContext();
    const [bookmarkName, setBookmarkName] = useState("");
    const [bookmarkConfirmOpen, setBookmarkConfirmOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchToDelete, setSearchToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [dataToBeRemoved, setDataToBeRemoved] = useState([]);
    const [savedList, setSavedList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchSavedSearch();
    }, [])

    useEffect(() => {
        if (context.needToFetchSavedSearch)
            fetchSavedSearch();
    }, [context.needToFetchSavedSearch])

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevSearch = usePrevious(context.selectedSavedSearch);

    useEffect(() => {
        if (context.selectedSavedSearch !== prevSearch && context.selectedSavedSearch) {
            const savedSearch = context.selectedSavedSearch;
            if (savedSearch && savedSearch.vendorNbr.length === 0) {
                searchItems(savedSearch.itemNbr, savedSearch.dcNbr[0]).then(searchResults => {
                    checkProblematicItemNbrs(searchResults);
                    context.searchCallback(Boolean(savedSearch.itemNbr.length), savedSearch.itemNbr, savedSearch.vendorNbr, savedSearch.dcNbr[0], searchResults, savedSearch.dcNbr);
                    context.selectSavedSearchCallback(null);
                    context.selectItemCallback(searchResults[0]);
                }).catch(err => {
                    context.showMsgCallback('Something went wrong!');
                });
            } else {
                searchItemsForVendors(savedSearch.vendorNbr, savedSearch.dcNbr[0]).then(searchResults => {
                    let { itemInfo, validVendorNbrs } = searchResults;
                    checkProblematicVendorNbrs(validVendorNbrs, savedSearch.vendorNbr);
                    context.searchCallback(Boolean(savedSearch.itemNbr.length), savedSearch.itemNbr, savedSearch.vendorNbr, savedSearch.dcNbr[0], itemInfo, savedSearch.dcNbr);
                    context.selectSavedSearchCallback(null);
                    context.selectItemCallback(itemInfo[0])
                }).catch(err => {
                    context.showMsgCallback('Something went wrong!');
                });
            }
        }
    }, [context.selectedSavedSearch])

    const onSaveSearch = () => {
        if (!bookmarkName || bookmarkName.length === 0) {
            setErrorMsg("Please provide a title");
            return;
        }
        const newSearch = new SavedSearch({
            itemNbr: context.showItemSearch ? context.searchedItemNbrList : null,
            vendorNbr: context.showItemSearch ? null : context.searchedVendorNbrList,
            dcNbr: context.searchedDcNbrList,
            searchName: bookmarkName,
            timeStamp: (new Date()).toISOString()
        });
        setIsLoading(true);
        setErrorMsg(null);
        addSavedSearch(newSearch.getPayload()).then(res => {
            setBookmarkName("");
            setIsLoading(false);
            setBookmarkConfirmOpen(true);
            // trigger refresh of saved search card
            context.setNeedToFetchSavedSearch();
        }).catch(err => {
            if (err.response.status === 409) {
                setErrorMsg("There is already a saved search with that name");
            } else {
                setErrorMsg(null);
                context.showMsgCallback('Something went wrong, please try again later!');
            }
            setIsLoading(false);
        });
    }

    const closeBookmarkDrawer = (type) => {
        setDataToBeRemoved([])
        setIsEdit(false);
        setBookmarkName("");
        if (type === "button-click" && isEdit)
            return;
        showHideBookmarkDrawer();
    }
    const alertDelete = () => {
        if (dataToBeRemoved.length > 0)
            setSearchToDelete(true)
    };
    const handleChange = (event) => {
        if (event.target.checked)
            setDataToBeRemoved([...dataToBeRemoved, event.target.name])
        else {
            const data = dataToBeRemoved.filter(data => data !== event.target.name);
            setDataToBeRemoved(data);
        }
    }

    const fetchSavedSearch = () => {
        getSavedSearch().then(savedSearchList => {
            setSavedList(savedSearchList);
            context.fetchSavedSearchCallBack(savedSearchList);
        }).catch(err => {
            context.showMsgCallback("Failed to fetch saved search!");
        });
    }
    const handleClick = (savedSearch) => () => {
        if (isEdit)
            return;
        context.selectSavedSearchCallback(savedSearch);
        showHideBookmarkDrawer();
    };

    const removeSavedSearch = () => {
        if (searchToDelete === null || isDeleting) return;
        try {
            let requests = [];
            setIsDeleting(true);
            dataToBeRemoved.map(data => requests.push(deleteSavedSearch(data)));
            Promise.all(requests).then(_ => {
                setIsEdit(false);
                setSearchToDelete(null);
                setIsDeleting(false);
                fetchSavedSearch();
            }).catch(error=>{
                setIsDeleting(false);
                context.showMsgCallback("Failed to delete saved search!");
            })
        } catch (error) {
            context.showMsgCallback("Failed to delete saved search!");
        }
    }
    const checkProblematicItemNbrs = (searchResults) => {
        const itemNumbers = context.selectedSavedSearch.itemNbr;
        if (searchResults.length !== itemNumbers.length)
            context.showMsgCallback("Please check if all items are valid and authorized");
    }
    const checkProblematicVendorNbrs = (validVendorNbrs, vendorNbrs) => {
        if (validVendorNbrs.length !== vendorNbrs.length)
            context.showMsgCallback("Please check if all vendor numbers are valid and authorized");
    }

    return (
        <>
        <Drawer
            sx={{
                width: "400px",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "400px",
                },
            }}
            ModalProps={{ onBackdropClick: () => closeBookmarkDrawer('backdrop-click') }}
            anchor="right"
            open={showBookmarkDrawer}
        >
            <DrawerHeader>
                <BookmarkTitle setIsEdit={setIsEdit} count={savedList?.length || 0} />
                <div className={styles.bookmarkForm}>
                    <div className={styles.textBox}>
                        <InputBase type="text"
                            placeholder="Enter bookmark name"
                            inputProps={{ maxLength: 25 }}
                            value={bookmarkName}
                            onChange={(e) => {
                                const { value } = e.target
                                setBookmarkName(value);
                            }}
                            disabled={context.searchResult === null}
                        />
                        {errorMsg && <span className={styles.error}><HelperText text={errorMsg} /></span>}
                    </div>
                    <SecondaryButton loading={isLoading} disabled={isLoading || context.searchResult === null} variant="outlined" onClick={onSaveSearch}>Add</SecondaryButton>
                </div>
            </DrawerHeader>
            <BookmarkList isEdit={isEdit} handleChange={handleChange} savedList={savedList} handleClick={handleClick} />
            <div className={styles.bookmarkBottomPanel}>
                <Button style={{ width: isEdit ? '168px' : '100%' }} className={styles.secondaryBtn} onClick={() => closeBookmarkDrawer('button-click')} variant="outlined">Cancel</Button>
                {isEdit && <Button className={styles.primaryBtn} onClick={alertDelete} variant="contained" data-testid="bookmark-remove-btn">Remove ({dataToBeRemoved.length})</Button>}
            </div>

        </Drawer>
        <CommonAlert
            open={bookmarkConfirmOpen}
            title="Search saved"
            message="You will be able to perform this search again by selecting it from the “Bookmarks” panel in the header."
            iconType={AlertIconType.success}
            primaryBtnTitle="Close"
            primaryBtnAction={() => setBookmarkConfirmOpen(false)}
            data-testid="alert-bookmark-save"
        />
        <CommonAlert
            open={searchToDelete !== null}
            title={`Are you sure you want to delete ${dataToBeRemoved.length > 1 ? 'these bookmarks' : 'this bookmark'}?`}
            message="Once deleted, it can not be undone."
            loading={isDeleting}
            iconType={AlertIconType.warning}
            primaryBtnTitle="Delete"
            primaryBtnAction={removeSavedSearch}
            secondaryBtnTitle="Cancel"
            secondaryBtnAction={() => setSearchToDelete(null)}
            data-testid="alert-bookmark-remove"
        />
        </>
    )
}

const BookmarkList = ({ isEdit, handleChange, savedList, handleClick }) => {
    return (
        <div className={styles.bookmarkListWrapper}>
            <div className={styles.bookmarkList} data-testid="bookmark-list-wrap" >
                {savedList.map(item => <div key={item.searchName} className={clsx(styles.bookmarkItem, isEdit ? styles.boomarkItemEdit : '')} onClick={handleClick(item)} data-testid={`bookmark-item-${item.searchName}`}>
                    {isEdit && <Checkbox
                        name={item.searchName}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        className={styles.bookmarkCheckbox}
                        sx={{
                            color: '#597796',
                            '&.Mui-checked': {
                                color: '#0071E9',
                            },
                        }}
                    />}{item.searchName}</div>)}
            </div>
        </div>
    )
}

export default Bookmark;