import React, { useContext } from 'react';
import Context from '../context/Context';

import styles from "./../styles/landingPage.module.scss";
import { ReactComponent as Ellipse } from '../assets/landingPageIcons/ellipse.svg';
import { ReactComponent as RectangleMain } from '../assets/landingPageIcons/rectangle_last.svg';
import { ReactComponent as RectangleMid } from '../assets/landingPageIcons/rectangle_back.svg';
import { ReactComponent as RectangleFront } from '../assets/landingPageIcons/rectangle_front.svg';
import { ReactComponent as FolderFileWhite } from '../assets/landingPageIcons/folderFileWhite.svg'
import { ReactComponent as FolderFileIcon } from '../assets/landingPageIcons/folderFile.svg';
import { ReactComponent as SearchIcon } from '../assets/landingPageIcons/search.svg';

const LandingPage = () => {
    const context = useContext(Context);

    return(
        <div className={styles.mainContainer}>
            <div className={styles.iconContainer}>
                <Ellipse className={styles.ellipseIcon} />
                <RectangleMain className={styles.mainRectangle} />
                <RectangleMid className={styles.midRectangle} />
                <RectangleFront className={styles.frontRectangle} />
                <FolderFileWhite className={styles.folderFileWhite} />
                <FolderFileIcon className={styles.folderFile} />
                <SearchIcon className={styles.searchFileIcon} />
            </div>           
            <div className={styles.mainContent}>Search for items, vendors & DC’s</div>
            <div className={styles.subContent}>Use the search and filter functionality to find what you are looking for. 
                You can also try loading a bookmark if you have one saved in the top right corner.</div>
            <div className={styles.searchFilter} onClick={context.showHideSidenav}>Search & Filter</div>
        </div>
    )
};

export default LandingPage;