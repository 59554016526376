import React, { Component } from 'react';
import impstyles from '../styles/SearchStyles.module.scss';
import Context from '../context/Context';
import {} from './common/alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { addSavedSearch } from '../services/apiService';
import { getUserNameFromUser } from '../services/otherService';
import SavedSearch from '../models/SavedSearch';
import {
  SavedSearchInputAlert,
  CommonAlert,
  AlertIconType,
} from './common/alert';
import { CustomTooltip } from './common/NavigationButton';
import SearchResultItem from './searchResultItem';

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedID: null,
      loading: false,
      errorMsg: null,
      open: true,
      savedSearchInputDialogOpen: false,
      savedSearchConfirmOpen: false,
    };
  }
  currentResults = [];
  preSelectFirstResult() {
    //console.log("this.context", this.context)
    if (
      this.currentResults !== this.context.searchResult &&
      this.context.searchResult !== null &&
      this.context.searchResult.length > 0
    ) {
      this.currentResults = this.context.searchResult;
      if (this.currentResults.includes(this.context.selectedItem)) {
        this.handleClick('', this.context.selectedItem)
      } else {
        this.handleClick('', this.context.searchResult[0]);
      }
      this.setState({ open: true });
    }
  }

  handleClick = (event, row) => {
    //console.log("onclick", row);
    this.setState({ selectedID: row['itemNbr'] });
    this.context.selectItemCallback(row);
    return true;
  };

  render() {
    const { classes } = this.props;
    this.preSelectFirstResult();
    return (
      <Context.Consumer>
        {(context) => (
          <div className={impstyles.CardOutline}>
            {context.searchResult !== null ? (
              <div
                className={impstyles.collapseResult}
                in={this.state.open}
                timeout="auto"
                unmountOnExit
              >
                <div className={impstyles.searchResultsHeader}>
                  <div className={impstyles.topLine} />
                  <span className={impstyles.resultsTitle}>
                    {context.searchResult.length} Results
                  </span>
                </div>
                <List
                  component="div"
                  className={impstyles.searchList}
                  disablePadding
                >
                  {context.searchResult.map((row, indexKey) => (
                    <SearchResultItem item={row} selectedID={this.state.selectedID} handleClick={this.handleClick} key={indexKey} />
                  ))}{' '}
                </List>{' '}
              </div>
            ) : (
              <Collapse
                className={impstyles.collapseNoResult}
                in={this.state.open}
                timeout="auto"
                unmountOnExit
              >
                <span>No results</span>
              </Collapse>
            )}{' '}
          </div>
        )}
      </Context.Consumer>
    );
  }
}

SearchResult.contextType = Context;
export default SearchResult;
