export default class SearchResult {
  constructor(data) {
    this.itemNbr = data['itemNbr'];
    const itemDesc = data['itemDesc'] ?? "";
    this.itemDesc = itemDesc.trim();
    const item2Desc = data['item2Desc'] ?? "";
    this.item2Desc = item2Desc.trim();
    this.legacyItemNbr = data['legacyItemNbr'] ?? null;
    // this.nineDigitVendorNbr = data['nineDigitVendorNbr'] ?? "";
    // this.vendorNbr = data['vendorNumber'] ?? null;
    this.vendorNbr = data['nineDigitVendorNbr'];
    const vendorName = data['vendorName'] ?? null;
    this.vendorName = vendorName.trim();
    this.upcNbr = data['upcNbr'] ?? null;
    this.category = data['category'] ?? null;
    this.itemImgUrl = `https://scene7.samsclub.com/is/image/samsclub/` + "0".repeat(13-this.upcNbr.toString().length) + this.upcNbr ?? null;
  }

  static getDummyObj() {
    return new SearchResult(
      {
        "itemNbr": 980070644,
        "itemDesc": "MM INSULATED SHOPPER     ",
        "legacyItemNbr": 980070644,
        "itemDesc2": "ORDER # 1/8\"TRIM         ",
        "nineDigitVendorNbr": "690222640",
        "upcNbr": 40643044169,
        "vendorName": "SAMS FRESH REPLENISHMENT      ",
        "vendorNbr": 690222,
        "category": 76
      }
    );
  }
}