import moment from 'moment';

export default class MetaData {
  constructor(data) {
    const startDateStr = data['beginingDate'] ?? "";
    const endDataStr = data['endDate'] ?? "";
    const dcList = data['dcList'] ?? [];
    // parsed variable
    this.dcNbrList = dcList.map(dc=>dc.dcNbr);
    this.startDate = moment(startDateStr);
    this.endData = moment(endDataStr);
  }

  static getDummyObj() {
    return new MetaData(
      {
        "beginingDate": "string",
        "cacheLoadTime": "string",
        "currentWkNbr": 0,
        "currentWmtYrWkNbr": 0,
        "currentYear": 0,
        "dcList": [
          {
            "dcNbr": 0,
            "virtualDc": 0
          }
        ],
        "endDate": "string"
      });
  }
}