import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useMyContext } from '../context/Context';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { saveComment } from '../services/apiService';
import impstyles from '../styles/CommentStyles.module.scss';
import { ReactComponent as Pencil } from "../assets/Pencil-icon.svg";

import { PrimaryButton, SecondaryButton } from '../components/common/customButton';

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
         },
    },
    textBox: {
        root: {
            width: '100%'
        }
    }
}));


const HostComments = (props) => {

    const cmtTxt = props.legacyCmtText;

    return cmtTxt === '' || cmtTxt === undefined ?
        <> </> :
        <>
            <p className={impstyles.noteText}>
                <span>Host Message:</span>
            </p>
            <p className={impstyles.noteText}>{cmtTxt}</p>
        </>
}

const TextEditor = (props) => {
    const classes = useStyles();
    let text = props.commentObj['commentTxt'];
    const [value, setValue] = useState(text);
    const [isLoading, setLoading] = useState(false);

    const context = useMyContext();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const updateComment = () => {
        props.commentObj['commentTxt'] = value;
        setLoading(true);
        saveComment(
            context.selectedItem.itemNbr,
            context.selectedItem.legacyItemNbr,
            context.searchedDCNbr,
            props.commentObj
        ).then(() => {
            setLoading(false);
            props.updateCmtTxt(value);
        }).catch(err => {
            console.error(err);
            context.showMsgCallback('DDIR Save comment Failed');
        });
    }
    const discardChange = () => {
        setValue(text);
        props.updateCmtTxt(text);
    }

    return (<div className={impstyles.textEditor}>
        <span className={impstyles.textBox}>
            <form>
                <InputBase
                    value={value}
                    multiline
                    maxRows={2}
                    fullWidth
                    onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                        )}
                    onChange={handleChange}
                />
            </form>

        </span>
        <span className={impstyles.noteButtons}>
            <Button className={impstyles.closeButton} variant="text" onClick={discardChange}><div>Close</div></Button>
            <PrimaryButton
                className={classes.button}
                onClick={() => updateComment(value)}
                disabled={text === value && !isLoading}
                variant="contained"
                loading={isLoading}
                fullWidth={true}
                color="primary">
                Save
                    </PrimaryButton>
        </span>
    </div>)
}


const RegualarComments = (props) => {
    const context = useMyContext();
    const classes = useStyles();
    const commentObj = props.commentObj;
    let commentTxt = commentObj['commentTxt'];
    let [editing, setEditing] = useState(false);

    const updateCmtTxt = (val) => {
        commentTxt = val;
        setEditing(!editing);
    }
 
    const changeEdit = () => {
        setEditing(!editing);
    }

    return (
        <>
        <p className={impstyles.noteText}>
            <span>Notes:</span>{commentTxt === '' && !editing && <Pencil className={context.itemAvailable ? '' : impstyles.iconDisabled} onClick={() => { if(context.itemAvailable)setEditing(true) }} />}
        </p>
            {commentTxt && !editing && <p className={impstyles.noteText}>{commentTxt}<Pencil onClick={() => { setEditing(true) }} /></p> }
    { editing && <TextEditor updateCmtTxt={updateCmtTxt} commentObj={commentObj} /> }
        </>
    )
}



const commentSection = (props) => {
    return (
        <>
            <HostComments legacyCmtText={props.commentObj.legacyCmtText} />
            <RegualarComments commentObj={props.commentObj} />
        </>
    );
}

export default commentSection;
