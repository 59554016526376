import React, { useEffect, useState, useContext } from 'react';
import Context from "../context/Context";
import impstyles from '../styles/SearchStyles.module.scss';
import ListItem from '@material-ui/core/ListItem';
import { getItemDetails } from '../services/apiService';
import { CustomTooltip } from './common/NavigationButton';

const SearchResultItem = ({ item, selectedID, handleClick, key }) => {
  const context = useContext(Context);
  const [itemInfo, setItemInfo] = useState({});

  return (
    <CustomTooltip
      title={`${item.vendorName} #${item.vendorNbr} Cat. #${item.category}`}
      tipPosition="left"
      data-testid="SearchResultItem"
    >
      <ListItem
        button
        selected={selectedID === item.itemNbr}
        key={key}
        data-testid="ListItem"
        className={
          impstyles.searchListItem +
          (selectedID === item.itemNbr
            ? ' ' + impstyles.searchListItemSelected
            : '')
        }
        onClick={(e) => {
          handleClick(e, item);
        }}
      >
        <div className={impstyles.itemContainer}>
          <img
            height="72"
            width="72"
            className={impstyles.itemImage}
            src={
              item.itemImgUrl
                ? item.itemImgUrl
                : "/No_Image.jpg"
            }
            alt="Item"
            border="0"
            float="center"
          />
          <div className={impstyles.itemInfo}>
            <div className={impstyles.itemNumber} component="th" data-testid="itemNumber">
              {item.itemNbr}
              <br />
            </div>
            <div className={impstyles.itemDescribe} data-testid="itemDescription">{item.itemDesc}</div>
          </div>
        </div>
      </ListItem>
    </CustomTooltip>
  );
};

export default SearchResultItem;
