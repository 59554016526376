/* eslint-disable no-use-before-define */
import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../styles/Autocomplete.css";
import HelperText from './common/errorHelperText';
var _ = require('lodash');

class Autocomp extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value ?? [], inputValue: "", errormsg: props.errormsg}
  }
  componentDidUpdate(prevProps) {
    if (prevProps.errormsg !== this.props.errormsg) {
      this.setState({ errormsg: this.props.errormsg });
    }
    if (!_.isEqual([...this.props.value].sort(), [...this.state.value].sort())) {
      this.setState({ value: this.props.value });
    }
  }
  setValue(val) {
    this.setState({ value: [...new Set(val)], errormsg: "" }, () => {
      this.props.newAAutoVal(this.state.value)
      const scrollElement = document.querySelector("#autocomp .MuiInputBase-root");
      if (scrollElement)
        scrollElement.scrollTop = scrollElement.scrollHeight;
    })
  }

  setInputValue(val) {
    this.setState({ inputValue: val });
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let val = this.state.value.map(data => data.toString())
        .concat(e.target.value)
        .map(x => x.trim())
        .filter(x => x);
      this.setValue(val);
    }
  }

  render() {
    return (
      <div>
        <Autocomplete
          disableClearable={true}
          multiple
          freeSolo
          autoSelect={true}
          id="renderInput"
          options={[]}
          value={this.state.value}
          inputValue={this.state.inputValue}
          onChange={(event, newValue) => {
            this.setValue(newValue);
          }}
          onKeyDown={this._handleKeyDown}
          onInputChange={(event, newInputValue) => {
            const options = newInputValue.split(/\r\n|\r|\n|,|\t|\s/);

            if (options.length > 1) {
              this.setValue(
                this.state.value.map(data => data.toString())
                  .concat(options)
                  .map(x => x.trim())
                  .filter(x => x)
              );
            } else {
              this.setInputValue(newInputValue);
            }
          }}
          renderInput={params => (
            <TextField
              error={this.state.errormsg.length > 0}
              id="outlined-error-helper-text"
              helperText={this.state.errormsg.length > 0 && <HelperText text={this.state.errormsg} />}
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true ,className:"autoCompleteCss"}}
              multiline
              variant="outlined"
              maxRows={Infinity}
              minRows="1"
            // placeholder="Use , as a delimiter"
            />
          )}
        />
      </div>
    );
  }
}

export default Autocomp;