import React from 'react';
import impstyles from '../../styles/DownloadComponentStyles.module.scss';
import {ReactComponent as SelectDcAlert} from "../../assets/alert-select-dcs.svg";

export default function selectDcErrorMessage (props) {

    return (
        <div className = {impstyles.errorMessageBox}>
            <SelectDcAlert className = {impstyles.errorIcon}/>
            <p className = {impstyles.errorMessage}>{props.errorMessage}</p>
        </div>
       
    );
}