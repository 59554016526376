import React, { useState } from 'react';
import styles from "../styles/bookmark.module.scss";
import { ReactComponent as BookmarkIcon } from "../assets/bookmark.svg";
import { ReactComponent as Kebab } from "../assets/header_icons/Kebab.svg";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const StyledMenu = styled((props) => (
    <Menu
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '12px !important',
        border: '1px solid #A6CDF7'
    },
}));

const BookmarkTitle = ({ setIsEdit, count }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl) && parseInt(count) !== 0;

    const handleOptionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = (value) => {
        if (value === 'edit') {
            setIsEdit(true);
        }
        setAnchorEl(null);
    };

    return (
        <div className={styles.bookmarkHeader} >
            <div className={styles.bookmarkIcon}>
                <BookmarkIcon />
            </div>
            <div className={styles.bookmarkHeading}>
                <h3 className={styles.headerTextCss}>Bookmarks</h3>
                <h6 className={styles.headerSubtextCss}>{count} saved</h6>
            </div>
            <div className={clsx(styles.bookmarkOption, parseInt(count) === 0 && styles.bookmarkDisabled)}>
                <span aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOptionClick}
                    style={{ background: open ? '#ffffff' : '' }}
                    data-testid="bookmark-menu"
                >
                    <Kebab />
                </span>
                <StyledMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleDropdownClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    className={styles.bookmarkOptionMenu}
                    data-testid="bookmark-option"
                >
                    <MenuItem className={styles.bookmarkOptionitem} onClick={() => handleDropdownClose('edit')} data-testid="opt-delete">Delete bookmarks</MenuItem>
                </StyledMenu>
            </div>
        </div>
    )
}

export default BookmarkTitle;