import Dialog from '@material-ui/core/Dialog';
import React, { useState, useEffect } from 'react';
import {PrimaryButton, SecondaryButton} from './customButton';
import {ReactComponent as InfoIcon} from '../../assets/alert-icon-info.svg';
import {ReactComponent as WarningIcon} from '../../assets/alert-icon-warning.svg';
import {ReactComponent as SuccessIcon} from '../../assets/alert-icon-success.svg';
import {TextField, FormControl} from "@material-ui/core";
import HelperText from './errorHelperText';
import   "../../styles/alertComponent.css";

const DownloadAlert = props => {
    const { open, handleClose } = props;
    return (<BaseAlert open={open} >
            <InfoIcon style={styles.icon} />
            <h1 style={styles.header}>Preparing download</h1>
            <div style={styles.content}>
                Your Excel file is being prepared. This could take some time. 
                Please leave this browser window open and the file will start to download when it is ready.
                </div>
            <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
    </BaseAlert>);
}

// enum for message alert style
const AlertIconType = {
    none: 0,
    info: 1,
    warning: 2,
    success: 3,
}

const CommonAlert = props => {
    const { open, iconType, title, message, loading,
        primaryBtnTitle, secondaryBtnTitle,
        primaryBtnAction, secondaryBtnAction 
    } = props;
    let AlertIcon;
    switch(iconType) {
        case AlertIconType.info:
            AlertIcon = (props)=> (<InfoIcon {...props}/>)
            break;
        case AlertIconType.warning:
            AlertIcon = (props)=> (<WarningIcon {...props}/>)
            break;
        case AlertIconType.success:
            AlertIcon = (props)=> (<SuccessIcon {...props}/>)
            break;
        default:
            AlertIcon = ()=> null;
    }
    return (<BaseAlert open={open} >
            <AlertIcon style={styles.icon} />
            <h1 style={styles.header}>{title}</h1>
            <div style={styles.content}>
                {message}
            </div>
            <div style={styles.btnContainer}>
                {secondaryBtnTitle ? <SecondaryButton disabled={loading} style={styles.secondaryBtn} onClick={secondaryBtnAction}>{secondaryBtnTitle}</SecondaryButton>:null}
                <PrimaryButton loading={loading} onClick={primaryBtnAction}>{primaryBtnTitle}</PrimaryButton>
            </div>
    </BaseAlert>);
}

const SavedSearchInputAlert = props => {
    const { open, handleClose, handleSubmit, errorMsg, loading} = props;
    const [searchName, setSearchName] = useState("");
    useEffect(() => {
        setSearchName("");
      },[props.open]);
    return (<BaseAlert open={open} >
            <h1 style={{...styles.header, textAlign: 'left'}}>Save this search</h1>
            <div style={styles.savedSearchInputTitle}>Title</div>
            <TextField 
                size="small" 
                fullWidth
                style={styles.savedSearchInput}
                inputStyle ={{width: '100%'}}
                id="outlined-error-helper-text" 
                label="" 
                type="search" 
                variant="outlined" 
                value={searchName}
                inputProps={{ fullWidth: true }}
                onChange={(e) => {
                    const {value} = e.target
                    setSearchName(value);
                }}
                inputProps={{ maxLength: 25 }}
                error = {errorMsg}
                helperText={errorMsg ? (<HelperText text = {errorMsg} />) :
                 (<span style={styles.savedSearchInputHint}>
                     {`${searchName.length}/25`}
                 </span>)}
            />
            <div style={styles.btnContainer}>
                <SecondaryButton disabled={loading} style={styles.secondaryBtn} onClick={handleClose}>Cancel</SecondaryButton>
                <PrimaryButton loading={loading} onClick={()=>handleSubmit(searchName)}>Save</PrimaryButton>
            </div>
    </BaseAlert>);
}

const BaseAlert = props => {
    const {open, children} = props;
    return <Dialog open={open} 
            PaperProps={{
                style: { borderRadius: 12 }
            }} disableEnforceFocus > 
        <div style={styles.container}>
            {children}
        </div>
    </Dialog>;
};

const styles = {
    container: {
        padding:'24px', 
        minWidth:'200px',
        maxWidth: '300px'
    },
    icon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    header: {
        marginBottom: '13px',
        width: '100%',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#00172f',
    },
    content: {
        marginBottom: '20px',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#1a426d'
    },
    btnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    secondaryBtn: {
        marginRight: '15px'
    },
    savedSearchInputTitle: {
        fontSize: '14px',
        fontWeight: '500',
        width: '300px',
        color: '#33577d',
        marginBottom: '5px'
    },
    savedSearchInput: {
        marginBottom: '20px',
        width: 300
    },
    savedSearchInputHint: {
        color: '#33577d',
    },
}

export { DownloadAlert, CommonAlert, AlertIconType, SavedSearchInputAlert };