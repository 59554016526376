import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import Fade from '@mui/material/Fade';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#F5F6F7",
    fontSize: 12,
    fontFamily: "canada-type-gibson",
    fontWeight: 400,
    height: 18,
    color: "#004368",
    border: "1px solid #CCD5DF",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    margin: 0,
    paddingTop: 6,
    paddingBottom: 4,
    position: "relative",
    left: -8,
    top: -8,
    boxShadow: "0px 8px 24px rgba(0, 39, 81, 0.15)",
    maxWidth: "none",
  },
  tooltipLeft: {
    left: 16,
    top: -15,
  },
  chevronButton: {
    minWidth: 32,
    minHeight: 32,
    background: "none",
    border: "1px solid #CCD5DF",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  iconButton: {
    padding: 0,
    background: "none",
    border: "none",
    minHeight: 24,
    minWidth: 24,
    width: 24,
    marginRight: 8,
    marginLeft: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  iconDropdown: {
    cursor: "grabbing",
  },
});

const NavigationButton = (props) => {
  const classes = useStyles();

  let tipPlacement,
    tipClasses,
    buttonClasses = "";

  // Conditional Tooltip styles
  if (props.tipPosition === "left") {
    tipPlacement = "left-start";
    tipClasses = `${classes.tooltip} ${classes.tooltipLeft}`;
  }

  if (props.tipPosition === "right") {
    tipPlacement = "right-start";
    tipClasses = classes.tooltip;
  }

  // Conditional Button styles
  if (props.type === "icon") {
    buttonClasses = classes.iconButton;
    if (props.openDropdown) {
      buttonClasses = `${classes.iconButton} ${classes.iconDropdown}`;
    }
  }

  if (props.type === "nav") {
    buttonClasses = classes.chevronButton;
  }

  return (
    <Tooltip
      title={!props.disabled ? props.title : ""}
      id={props.id + "-tootlip"}
      placement={tipPlacement}
      classes={{ tooltip: tipClasses }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <button
        className={buttonClasses}
        id={props.id}
        aria-describedby={props.id + "-tooltip"}
        onClick={props.clickHandler && props.clickHandler}
        aria-expanded={props.ariaExpanded}
        aria-controls={props.ariaControls}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </Tooltip>
  );
};

const CustomTooltip = (props) => {
  const classes = useStyles();

  let tipPlacement, tipClasses = "";

  if (props.tipPosition === "left") {
    tipPlacement = "left-start";
    tipClasses = `${classes.tooltip} ${classes.tooltipLeft}`;
  }

  if (props.tipPosition === "right") {
    tipPlacement = "right-start";
    tipClasses = classes.tooltip;
  }

  return (
    <Tooltip
      title={props.title}
      id={props.id + "-tootlip"}
      placement={tipPlacement}
      classes={{ tooltip: tipClasses }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
        {props.children}
    </Tooltip>
  );

}

export { NavigationButton, CustomTooltip };
