/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../styles/MultiSelect.css"
import { ReactComponent as DownArrowIcon } from "../assets/down-arrow-blue.svg";

const CustomAutocomplete = withStyles({
 inputRoot: 
 {
  "& .MuiAutocomplete-tag": 
   {
     width: 65,
     fontSize: 12,
     height: 13,
     float: "left"
   },
  "& .MuiChip-deletable": 
  { 
     height: 22
  },
  "& .MuiAutocomplete-input": 
  {   
  maxWidth: 50   
  } 
 } 
})(Autocomplete);

const MultiSelect = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
  searchedDcValues,
  dcResetKey,
  triggerSearch
}) => {
    
  const [selectedOptions, setSelectedOptions] = useState(searchedDcValues);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = selectedOptions =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => {
    setSelectedOptions([]);
    onChange([]);
  }
  const itemsPlusAll = [selectAllLabel, ...items];
  const getOptionLabel = option => `${option}`;
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  useEffect(() => {
    setSelectedOptions(searchedDcValues)
  }, [searchedDcValues]);

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if(event.key === "Enter" && reason === "remove-option"){
      return triggerSearch(event);
    }
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find(option => option === selectAllLabel)) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter(el => el !== selectAllLabel);
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
     
    const selectAllProps =
      option === selectAllLabel // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <span >
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
      {getOptionLabel(option)}</span>
    );
  };
  const inputRenderer = params => (
    <TextField  {...params} label={label} placeholder={placeholder} variant="outlined"  aria-invalid={false}/>
  );
  const getOptionSelected = (option, anotherOption) =>
    option === anotherOption;
  const filter = createFilterOptions();
  return (
    <CustomAutocomplete
     id="customAutoComplete"
      multiple
      size="small"
      limitTags={limitTags}
      options={itemsPlusAll}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      popupIcon={<DownArrowIcon/>}   
      autoHighlight={true}
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 2,
  items: [],
  selectedValues: [],
  getOptionLabel: value => value
};

export default MultiSelect;
